.RecentFollowersList{
	ul{
		padding-left: 0;
	}
	.FollowersInfo{
		display: flex;
		align-items: center;
		padding: 1rem 1.5rem;
		border-bottom: 1px solid $grayLine;
		img {
            border-radius: 100%;
            max-width: 60px;
        }
        .FollowersName{
        	margin-left: 15px;
            flex: 1;
            font-weight: 700;
        }
        
    }
    .FollowersViewAll{
		padding: 1.5rem 1rem 2rem;
    }
}