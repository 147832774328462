.Sidebar {
    background: $white;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, .1);

    &__Heading {
        padding: 20px;
        background: $red;

        h3 {
            margin: 0;
            color: $white;
            font-weight: lighter;
            font-size: 1.8rem;
        }
    }

    &__FilterList {
        padding: 20px 0;

        .formItem {
            padding: 0 20px;

            label {
                font-weight: bold;
            }

            .selectField {
                margin: 1rem 0 1.5rem;
            }
        }

        .SwitchItem {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            margin: 1rem 0 1.5rem;

            &:last-child {
                margin-bottom: 0;
            }
            .IconButton {
                color: $white;
                &:hover {
                    color: $black;
                }
            }
        }

        hr {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
}