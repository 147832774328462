.Pagination {
    margin: 0;
    text-align: center;
    display: flex;
    justify-content: flex-end;
    color: $black;
    padding: 10px 0;
    list-style: none;
    margin-right: 15px;

    li {
        &.page-item {
            display: inline-block;
            margin: 0 5px;
            font-size: 16px;
            vertical-align: baseline;

            // font-family: Roboto, 'sans-serif';
            a {
                color: $black;
                text-decoration: none;
                font-size: 16px;
                width: 30px;
                height: 30px;
                line-height: 1.8;
                transition: all .25s ease;
                text-transform: uppercase;
                font-weight: 600;
                padding: 0;
                display: inline-block;
                margin: 0;
                text-align: center;
                vertical-align: middle;
                float: none;
                color: rgba(0, 0, 0, 0.85);
                border-radius: 3px;
            }

            &:first-child {
                // margin-right: 0;

                a {
                    color: $red;
                    background-color: $white;
                    box-shadow: 1px 2px 4px 2px rgba(0, 0, 0, 0.2);
                }
            }

            &:last-child {
                // margin-left: 0;

                a {
                    color: $red;
                    background-color: $white;
                    box-shadow: 1px 2px 4px 2px rgba(0, 0, 0, 0.2);

                    &.page-link {
                        svg {
                            display: inline-block;
                            font-weight: bold;
                        }
                    }
                }
            }

            &.active {
                margin-right: 0;
                a {
                    background-color: #c84034;
                    border-color: #c84034;
                    width: 30px;
                    height: 30px;
                    line-height: 1.8;
                    color: $white;
                }
            }

        }


    }
}