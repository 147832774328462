.wrap {width: 100%;
    .Head.Underlined {
        margin-bottom:30px;
    }
 }

.accordion {
    display: block;
    position: relative;
    width: 100%; padding: 0;
    margin-bottom: 0;
    }

.accordion__item {
    display: block;
    width: 100%;
    background-color: $white;
    padding: 15px;
    margin-bottom: 25px;
    box-shadow: #d0d0d0 0px 0px 10px 0px;
    border-radius: 3px;
    &:last-child {
        margin-bottom: 0; }

      .accordion__title {
            display: block;
            position: relative;
            line-height: 40px;
            padding: 0px 50px 0 15px;
            font-size: 17px;
            font-weight: 700;
            color: #333;
            overflow: hidden;
            transition: background-color .2s;

            &:hover {
                background-color: #fafafa;
                color: $red; }

            &.active {
                background: transparent;
            
                & .x7 {
                transform: rotate(-90deg);}
                
                & .btop {
                    width: 67%;
                    top: 2px;
                    transform: translateY(-50%) rotate(-90deg);
                    transform-origin: 50% 50%; }
                & .bmid {
                    opacity: 0; }
                & .bbot {
                    width: 67%;
                    bottom: 2px;
                    transform: translateY(50%) rotate(90deg);
                    transform-origin: 50% 50%; }
            }
        }
    }

.accordion__content {
    display: none;
    position: relative;
    overflow: hidden;
    line-height: 1.65;
    padding: 5px 15px 15px 15px;
    background-color: #fff;
    font-size: 15px; 
    margin-bottom: 0;
    .TableBlock{
        overflow: scroll;
        padding-bottom: 20px;
        table{
            min-width: 1000px;
            min-height: 500px;
            background-color: $lightGrey;
            thead{
                tr{                
                    color: $white;
                    background: $white;
                    th{
                        padding: 5px;
                        text-align: center;
                        background-color: $lightSection;
                        font-size: 14px;
                        color: $lightGrey;
                        @media screen and (min-width: 768px){
                            font-size: 18px;
                            padding: 10px;
                        }
                    }
                }
            }
            tbody{
                tr{
                    background: $white;
                    th{
                        background-color: $base;
                        text-align: center;
                        font-size: 13px;
                        color: $lightGrey;
                        padding: 5px;
                        &:first-child{
                            background-color: $lightSection;
                        }
                        @media screen and (min-width: 768px){
                            font-size: 18px;
                            padding: 10px;
                        }
                    }
                    td{
                        text-align: center;
                        font-size: 14px;
                        padding: 10px;
                        span{
                            width: 7px;
                            height: 7px;
                            border-radius: 50%;
                            background-color: $lightGrey;
                            display: block;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }
    
}

// --- Icon ---
.bmenu { 
    width: 26px; 
    height: 16px; 
    display: block; 
    position: absolute;
    right: 15px;
    top: 12px; }

.btop, .bmid, .bbot {
    right: 0;
    width: 100%;
    height: 2px;
    position: absolute;
    background: #b72b2f;
    border-radius: 1px; }

.btop {
    width: 100%; }

.bmid {
    width: 60%; }

.bbot {
    width: 100%; }

.x7 {
    transition: transform .3s ease-out;
    transform-origin: 60% 75%;
    
    & .btop {
        top: 50%;
        transition: width .3s, transform .3s, top .0s;
        transition-timing-function: ease-out; }

    & .bmid {
        top: calc(50% - 1px);
        transform-origin: 100% 50%;
        transition: opacity .1s;
        transition-timing-function: ease-out; }

    & .bbot {
        bottom: 40%;
        transition: width .3s, transform .3s, bottom .0s;
        transition-timing-function: ease-out; 
        transform: translateY(50%) rotate(90deg);
        transform-origin: 50% 50%;
        }
}