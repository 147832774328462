$base: #000;
$black: #000;
$white: #fff;
$red: #ED3237;
$lightGrey: #666666;
$lightBackground: #F7F5F5;
$darkBackground: #333333;
$primaryColor: #cccccc;
$inputColor: #666666;
$grayLine: #e5e5e5;
$inputBackground: #f5f4f7;
$Blue: #00b1e7;
$Green: #00b54e;
$labelColor: #999999;
$facebookColor: #3f4e99;
$googleColor: #fd0018;
$lightSection: #e6ebed;

$primaryFont: 'Raleway',
sans-serif;
$secondaryFont: 'Roboto Slab',
sans-serif;