.homeForm {
    margin-top: -10rem;
    margin-bottom: 0;
    background: #fff;
    position: relative;
    z-index: 3;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
    display: flex;
    justify-content: space-between;

    .formItem {
        flex: 0 0 90%;
        padding: 20px 30px;
        background: $white;
        border: 0 !important;

        label {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 4px;
            line-height: 1;
            display: block;
        }

        .selectField {
            display: flex;
            justify-content: space-between;

            >.select {
                color: $inputColor;
                border-radius: 3px;
                // border: none;
                height: auto;
                width: 100%;
            }

            >.select:first-of-type {
                margin-right: 30px;
            }

            .selectize-input {
                padding: 15px;
                border: 1px solid #f4f0f0;
                box-shadow: none;
                background: $inputBackground;
                outline: none;
                font-size: 16px;

                >input {
                    // width: 100% !important;
                    font-size: 16px;
                    color: $inputColor;
                    &[type="select-one"] {
                        color: $inputColor;
                    }
                    &::placeholder {
                        color: #888 !important;
                    }
                }

                &::placeholder {
                    color: #888 !important;
                }
            }

            .selectize-dropdown {
                border: none;
                box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
                font-size: 15px;
                color: $inputColor;
                .active {
                    background: $red;
                    color: $white;
                    font-weight: bold;
                }
            }

            .selectize-dropdown [data-selectable].option {
                padding: 15px;
            }

        }
    }

    .searchButton {
        flex: 1;
        background: $red;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background .3s ease;

        &:hover {
            background: darken($red, 15%);
        }

        button {
            height: 100%;
            width: 100%;
            cursor: pointer;
            background-color: transparent;
            color: #fff;
            border: none;

            svg {
                width: 33px;
                @include Ease(all, 0.25s);
                transform: scale(0.9);
            }
            &:hover{
                svg{
                    transform: scale(1) rotate(-10deg);
                }
            }
        }

        // a {
        //     color: $white;
        //     font-size: 20px;
        //     display: flex;
        //     height: 100%;
        //     width: 100%;
        //     justify-content: center;
        //     align-items: center;

        //     svg {
        //         width: 30px;
        //     }
        // }

    }
}

@media only screen and (max-width: 1023px) {
    .homeForm {
        margin-top: -8rem;
        margin-bottom: 30px;
        flex-direction: column;

        .formItem {
            flex: 0 0 100%;

            label {
                margin-bottom: 6px;
            }

            .selectField {
                flex-direction: column;

                >.select:first-of-type {
                    margin-right: 0;
                    margin-bottom: 20px;
                }

                >.select {
                    border-bottom: thin solid rgba($lightGrey, .3);
                    border-radius: 0;
                    // background-color: $lightBackground;
                }

                .select:not([multiple]):not([size]) {
                    background-position: 100% 50%;
                }

                .selectize-input {
                    padding: 0 8px;
                    background: $white;
                    border: none;
                }
            }

        }

        .searchButton {
            margin: 25px 30px;
            margin-top: 0;
            padding: 12px 24px;
            border-radius: 3px;
        }
    }
}
