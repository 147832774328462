* {
    box-sizing: border-box;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

html {
    font-size: 62.5%;
    background: $white;
}

body {
    font: 400 16px/1.4 $primaryFont;
    color: $base;
    transition: all .4s .3s ease-in;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // display: none;
    // &.bodyShow{
    //     display: block;
    // }
}

body,
html {
    min-height: 100vh;
    padding: 0;
    margin: 0;
    // overflow: hidden;
}

main{
    display: none;
    &.mainShow{
        display: block;
    }
}

#preloaderWrapper{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 1099;
    height: 100%;
    @include FlexCenter;
    #preloaderInner{
        text-align: center;
        position: relative;
        img{
            width: 70%;
        }
        .preloadeRotate{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
            &:before{
                content: '';
                position: absolute;
                border-radius: 50%;
                border: 3px dashed $red;
                width: 85px;
                height: 85px;
                animation: rotation 6s linear infinite;
            }
        }
    }
}


@keyframes rotation{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}


p {
    margin-top: 0;
    margin-bottom: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-family: $secondaryFont;
    font-weight: 700;
    line-height: 1.2;
    color: $base;
}

h1 {
    font-size: 2rem;
    @media only screen and (min-width: 768px) {
        font-size: 2.6rem;
    }
}

h2 {
    font-size: 2rem;

    @media screen and (min-width:768px) {
        font-size: 2.6rem;
    }
}

h3 {
    font-size: 2.2rem;
}

h4 {
    font-size: 1.5rem;
}

h5 {
    font-size: 1.25rem;
}

h6 {
    font-size: 1rem;
}

img {
    max-width: 100%;
}

img.FullWidth {
    width: 100%;
}

.Section5 {
    padding-bottom: 5rem;
    padding-top: 5rem;
}

.Section5-3 {
    padding-top: 5rem;
    padding-bottom: 3rem;
}

.Section3 {
    padding-bottom: 1rem;
    padding-top: 2rem;
}

.DesktopOnly {
    display: none;

    @media (min-width: 768px) {
        display: block;
    }
}

.MobileOnly {
    @media (min-width: 768px) {
        display: none;
    }
}

.MobileSpaceOnly {
    padding-top: 3rem;

    @media (min-width: 768px) {
        padding-top: 0;
    }
}

.Center {
    text-align: center;
}

.WhiteColor {
    color: $white;
}

.TextRed {
    color: $red;
}

.TextGray {
    color: $lightGrey;
}

.BGWhite {
    background-color: $white;
}

.BGLightGray {
    background-color: $grayLine;
}

.BGRed {
    background-color: $red;
}

.BGYellow {
    background-color: #d0b601;
}

.BGGreen {
    background-color: #009900;
}

.BGray {
    background-color: $lightGrey;
}

.BGGrayBorder {
    border: 1px solid $primaryColor;
    background-color: #f3f3f3;
}

.smallText {
    font-size: 1.35rem;
}

.Head {
    position: relative;

    &.Underlined {
        margin-bottom: 15px;
        padding-bottom: calc(15px + 4px);

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            background: $red;
            width: 70px;
            height: 4px;
        }

    }

    &.LeftBordered {
        padding-left: 10px;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            background: $red;
            width: 3px;
            height: 100%;
            @include Ease();
        }
    }

    &.Center {
        &::after {
            left: 50%;
            transform: translateX(-50%);
        }

    }
}

a,
a:link,
a:active {
    cursor: pointer;
    text-decoration: none;
    color: currentColor;
    display: inline-block;
}

a{
    &:hover{
        text-decoration: none !important;
    }
    &.NotLink{
        cursor: text;
        &:hover{
            color: inherit !important;

        }
    }
    &.NotLine{
        border-bottom: none;
        &:hover{
            border-bottom: none;
        }
    }
}


a.TextRed {
    color: $red;

    &:hover {
        text-decoration: underline;
    }
}

ul {
    list-style: none;
}

.M-Top2 {
    margin-top: 2rem;
}

.M-Bot3 {
    margin-bottom: 3rem;
}

.M-Top3 {
    margin-top: 3rem;
}

.M-Top4 {
    margin-top: 4rem;
}

.M-Bottom1 {
    margin-bottom: 1rem;
}

.M-Bottom2 {
    margin-bottom: 2rem;
}

.M-Bottom3 {
    margin-bottom: 3rem;
}

.M-Right2 {
    margin-right: 2rem !important;
}
.PaddingTop2 {
    padding-top: 2rem;
}
.PaddingTop1 {
    padding-top: 1rem;
}
.Padding2 {
    padding: 2rem;
}

.P-Bottom4 {
    padding-bottom: 4rem;
}

.P-Left0 {
    padding-left: 0;
}

.P-Left20 {
    padding-left: 20px;
}

.Border-Redius5 {
    border-radius: 5px;
}

.PaddingTop3 {
    padding-top: 3rem;
}

.NoPadding {
    padding: 0;
}

.ErrorText {
    color: $red;
    font-size: 1.45rem;
}

.uk-input {
    // border: none !important;
    outline: none !important;
    background: $lightBackground;
}

p.info {
    font-size: 14px;
}

.text-danger {
    color: $red;
    padding: 4px;
    font-size: 12px;
    font-weight: bold;
}

button:hover {
    cursor: pointer;
}
