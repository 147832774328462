label {
    color: #444;
    padding-bottom: 0.5rem;
    font-size: 1.4rem;
    display: inline-block;

    .RequiredStar {
        color: $red;
        font-size: 1.8rem;
        line-height: 0;
    }
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="date"],
select,
.selectize-input,
.uk-input,
textarea {
    color: $inputColor !important;
    padding: 15px 10px;
    border: 1px solid #f4f0f0;
    font-weight: bold;
    box-shadow: none;
    background: $inputBackground;
    outline: none;
    font-size: 16px;
    border-radius: 3px;
    width: 100%;
    overflow: hidden;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    line-height: 18px;
    -webkit-font-smoothing: inherit;

    &:focus,
    &:active {
        color: $inputColor;
        outline: none;
        background-color: $inputBackground;
        padding: 15px;
    }

    &::placeholder {
        color: #aaa;
        font-size: 15px;
    }
    >input {
        color: $inputColor;
        &::placeholder {
            color: #888 !important;
        }
    }
    &::placeholder {
        color: #888 !important;
    }
}

input {
    &.DashedInputType {
        background: transparent;
        border: unset;
        border-bottom: 2px dashed $primaryColor !important;

        &:focus {
            background: transparent;
        }
    }
}

input[type="checkbox"] {
    &::selection {
        background: #616161;
    }
}

.uk-select:required:invalid {
    color: $primaryColor !important;
    font-size: 1.4rem;
}

.uk-select {
    padding-left: 10px;

    option {
        background: $white;
        color: $inputColor;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        box-shadow: 0 0 10px 100px #f00 inset;
    }

    option[value=""][disabled] {
        display: none;
    }
}

//Custome Select
.customeSelectWrap {
    select {
        display: none;
    }

    .customeDropdown {
        height: 45px;
        color: $inputColor !important;
        // border: 1px solid darken($lightBackground, 10%) !important;
        border: none !important;
        background: $inputBackground;
        border-radius: 3px;
        line-height: 44px;
        box-sizing: border-box;
        cursor: pointer;
        display: block;
        outline: none;
        padding-left: 10px;
        padding-right: 30px;
        position: relative;
        text-align: left !important;
        font-size: 1.6rem;
        transition: all 0.2s ease-in-out;
        user-select: none;
        white-space: nowrap;
        width: auto;

        &:focus {
            color: $inputColor;
            outline: none;
            border-color: darken($inputBackground, 10%);
        }

        &:active {
            background-color: $inputBackground;
            // box-shadow: 0 1px 4px rgba(0,0,0,.05) inset;
        }

        // Arrow
        &:after {
            content: '';
            height: 15px;
            width: 9px;
            background-image: url(../img/arrow-new.svg);
            background-repeat: no-repeat;
            background-position: 50%;
            border: none;
            transform: origin(50% 20%);
            transition: all 0.125s ease-in-out;
            display: block;
            margin-top: -3px;
            pointer-events: none;
            position: absolute;
            right: 15px;
            top: 47%;
        }

        &.open {
            @extend :active;

            &:after {
                transform: rotate(-180deg);
            }

            .list {
                transform: scale(1);
                opacity: 1;
                pointer-events: auto;
                width: 100%;

                ul {
                    padding-left: 0;
                    margin-bottom: 0;
                }
            }

            .option {
                cursor: pointer;
            }
        }

        &.wide {
            width: 100%;

            .list {
                left: 0 !important;
                right: 0 !important;
            }
        }

        .list {
            width: 100%;
            box-sizing: border-box;
            transition: all .15s cubic-bezier(0.25, 0, 0.25, 1.75), opacity .15s linear;
            transform: scale(.75);
            transform-origin: 50% 0;
            box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.13);
            background-color: $white;
            border-radius: 0 0 3px 3px;
            border: 1px solid $grayLine;
            margin-top: 3px;
            padding: 3px 0;
            opacity: 0;
            overflow: hidden;
            pointer-events: none;
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 999;

            &:hover .option:not(:hover) {
                background-color: transparent !important;
            }
        }

        .option {
            cursor: default;
            font-weight: 400;
            line-height: 30px;
            outline: none;
            padding-left: 10px;
            padding-right: 29px;
            text-align: left;
            font-size: 1.45rem;
            transition: all 0.2s;

            &:hover,
            &:focus {
                background-color: #eee !important;
            }

            &.selected {
                font-weight: 600;
            }

            &.selected:focus {
                background: #eee;
            }
        }
    }
}

//Mobile Field with country code

.MobileField{
	display: flex;
	justify-content: flex-start;
	span{
		display: inline-block;
		&.CountryCode{
			width: 46px;
			border-right: 1px solid $primaryColor;
			input{
				height: 45px;
				width: 45px;
				text-align: center;
				font-size: 1.6rem;
				color: $darkBackground !important;
			}
		}
		&.MobileNumber{
			flex: 1;
		}
	}
}


.uk-select,
.uk-input {
    // height: 45px;
    color: $inputColor;
    height: auto;

    &:focus {
        // border-color: darken($inputBackground, 10%)
    }
}

.uk-checkbox {
    border: 1px solid darken($inputBackground, 20%);

    &:checked {
        background-color: $red;
        border-color: transparent;
        &:focus {
            background-color: $red;
        }
    }

    &:focus {
        border-color: darken($inputBackground, 20%);
    }
}

.uk-checkbox:indeterminate {
    background-color: $red;
    &:focus {
        background-color: $red;
    }
}



// placeholder color
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #dedede !important;
    font-size: 1.4rem;
}

select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input::-moz-placeholder {
    /* Firefox 19+ */
    color: #dedede !important;
    font-size: 1.4rem;
}

select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input:-ms-input-placeholder {
    /* IE 10+ */
    color: #dedede !important;
    font-size: 1.4rem;
}

select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input:-moz-placeholder {
    /* Firefox 18- */
    color: #dedede !important;
    font-size: 1.4rem;
}

#city_name_box {
    display: none;
}