.FormPageSection {
    .SubmitButton {
        margin-top: 3rem;

        a.RoundButton {
            padding: 17px 55px;
            font-weight: 700;
        }
    }

    &.NotificationSection {
        padding-top: 0;

        .FormWrapperBox {
            margin-top: 3rem;
            padding: 0;
            padding-bottom: 4rem;
        }

        .uk-width-1-1 {
            padding: 20px 30px;
            border-bottom: 1px solid rgba($lightGrey, .3);
        }

        .TextWithSwitcher {
            width: 100%;
            margin-left: 0;
        }
    }
}