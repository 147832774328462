.MaxWidth490{
	max-width: 490px;
}

.CompleteYourProfileSection{
	padding-top: 2rem;
	padding-bottom: 2rem;
	@media(max-width: 500px){
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}
	.HeadingWithRightSearch{
		display: block;
		justify-content: space-between;
		align-items: center;
		@media(min-width: 501px){
			display: flex;
		}
		.HeadingWithLefttIcon{
			padding-right: 20px;
			flex: 1;
			@media(max-width: 500px){
				margin-bottom: 30px;
				padding-right: 0;
			}
			@media(max-width: 330px){
				margin-bottom: 20px;
			}
			.IconBox{
				background-color: $white;
				border: 1px solid #e4e4e4;
				svg{
					fill: $darkBackground;
				}
			}
		}
		.SearchBoxWrapper{
			@media(max-width: 500px){
				padding-left: 20px;
				padding-right: 20px;
			}
			@media(max-width: 330px){
				padding-left: 10px;
				padding-right: 10px;
			}
		}
		.SearchField{
			display: flex;
			align-items: flex-start;
			input[type="text"]{
				padding: 1.2rem 1rem;
				border-radius: unset;
				border: 1px solid #e4e4e4;
			}
			.SearchButton{
				padding: 0.85rem 1rem;
			}
		}
	}
	.TextWithSwitcher{
		@media(max-width: 500px){
			width: 100%;
			padding-top: 1rem;
			margin-top: 1rem;
			border-top: 1px solid #ccc;
		}
	}
	.HeadingWithLefttIcon{
		@media(max-width: 500px){
			display: block;
			text-align: center;
		}
		.IconBox{
			@media(max-width: 500px){
				margin: 0 auto 1rem;
			}	
		}
	}
	.ButtonDiv{
		a{
			svg{
				width: 15px;
				height: 15px;
				fill: $white;
				margin-right: 5px;
				margin-top: -3px;
				@include Ease(all, 0.25s);
			}
			&:hover{
				svg{
					fill: $red;
				}
			}
		}
	}
}
.LeftSideBoxWrap{
	.ProjectCard{
		.uk-card-body{
			padding: 20px 10px;
		}
		.uk-card-footer{
			padding: 10px 10px;
		}
		.LikeCommentbox{
			margin: 0;
			padding: 0;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			li{
				display: flex;
				align-items: center;
				flex-direction: column;
				padding-right: 5px;
				font-size: 1.25rem;
				svg{
					width: 14px;
					height: 14px;
					margin: 0 0 0 0;
				}
			}
		}
	}
}

.ProjectAccordion{
	background-color: $white;
	padding: 3rem 2rem;
	box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.08);
	.ProjectHeaing{
		padding: 0.5rem 0rem 0.5rem;
	}
	.uk-accordion{
		margin-top: 10px;
	}
	li{
		&.uk-open{
			.uk-accordion-title{
				color: $red;
			}
		}
		.uk-accordion-title{
			width: 100%;
		    border: 1px solid #d8d8d8;
		    padding: 1rem 1rem 1rem 1.5rem;
		    font-size: 1.6rem;
		    font-weight: 600;
		}
		.uk-accordion-content{
	    	// padding: 2rem 1.5rem;
	    	margin-top: -6px;
	    	background-color: #efefef;
	    	border: 1px solid #d8d8d8;
	    	ul{
	    		padding-left: 0;
	    		li{
	    			a{
	    				width: 100%;
	    				padding: 0.7rem 1rem 0.7rem 1.5rem;
	    				border-bottom: 1px dashed #cecece;
	    				display: flex;
	    				align-items: center;
	    				font-size: 1.45rem;
	    				@include Ease(all, 0.25s);
	    				&:hover{
	    					color: $red;
	    				}
	    				&.is_Active{
	    					color: $red;
	    					background-color: #d8d8d8;
	    				}
	    				.circleIcon{
	    					width: 40px;
						    height: 40px;
						    background-color: $white;
						    border-radius: 50%;
						    /* box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.12); */
						    display: flex;
						    justify-content: center;
						    align-items: center;
						    border: 1px solid #d0d0d0;
	    				}
	    				svg{
	    					width: 22px;
	    					height: 22px;
	    				}
	    				span{
	    					margin-left: 5px;
	    				}
	    			}
	    			&:last-child{
	    				a{
	    					border-bottom: 0;
	    				}
	    			}
	    		}
	    	}
	    }
	}
}
.FollowersPageSection {
	.uk-grid>.uk-grid-margin {
			margin-top: 40px;
		}
	.uk-card-header {
		border-bottom: none;
	}
}
@media only screen and (max-width:960px) {
.ProfessionalAccordianSection {
		.ProfessionalSidebar {
        animation: SlideDown 0.8s;
        display: none;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 9996;
        overflow-y: auto;
        background: #fff;

        &.openSidebar {
            display: block;
            animation: SlideUp 0.3s;
            .MobileOnly {
                display: block;
                position: absolute;
                top: 10px;
                right: 20px;
                color: $red;
                font-weight: bold;
                font-size: 20px;
                .CloseSidebar {
                    cursor: pointer;
                     svg {
                        height: 25px;
                        width: 25px;
                    }
                }
               
            }
            .Sidebar__Heading {
                display: none;
            }
        }
    }
		

		.MobileFilterSidebar {
            display: block;
            width: 100%;
            position: relative;
            opacity: 1;
            z-index: 9999;
            .Sidebar__Heading {
                padding: 15px;
                width: 100%;
                position: fixed;
                left: 0;
                right: 0;
                bottom: 0;
                color: $white;
                font-weight: 600;
                font-size: 1.8rem;
                display: flex;
                justify-content: center;
            }
        }
	}
}