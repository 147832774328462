.LeftIconRightTextSectionContainer{
	padding: 1em 0 0;
	background:#f5f4f7;
}
.TextWithLefttIcon{
		display: block;
		align-items: center;
		text-align: center;
		@media(min-width: 500px){
			display: flex;
			text-align: left;
		}
		
		&.BGRed, &.BGGreen, &.BGYellow{
			color: $white;
			.IconBox{
				background-color: $white;
				svg{
					fill: $black;
				}
			}
			.headingBox{
				h4{
					color: $white;
					font-size: 1.85rem;
					font-weight: 400;
					margin-bottom: 0;
				}
			}
			.ButtonBox{
				margin-top: 1.5rem;
				@media(min-width: 500px){
					margin-top: 0;
				}
			}
			.RoundButtonTransparent{
				padding-right: 4.5rem;
				padding-right: 4.5rem;
				&:hover{
					background-color: $white;
					color: $red !important;
				}
			}
		}
		&.BGray{
			color: $white;
			.IconBox{
				background-color: $white;
				svg{
					fill: $black;
				}
			}
			.headingBox{
				h4{
					color: $white;
					font-size: 1.85rem;
					font-weight: 400;
					margin-bottom: 0;
				}
			}
		}
		.IconBox{
			background-color: $red;
			width: 65px;
			height: 65px;
			border-radius: 50%;
			box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.2);
			@include FlexCenter;
			margin: 0 auto 1rem;
			@media(min-width: 500px){
				width: 80px;
				height: 80px;
				margin: 0;
			}
			svg{
				fill: $white;
				width: 35px;
				height: 35px;
				margin-bottom: 0;
				@media(min-width: 500px){
					width: 48px;
					height: 48px;
				}
			}
			&.IconBox55{
				width: 55px;
				height: 55px;
				svg{
					width: 27px;
					height: 27px;
					fill: #090;
				}
			}
		}
		.headingBox{
			margin-left: 15px;
			flex: 1;
			h2{
				font-size: 2.2rem;
			}
			h5{
				font-weight: 400;
				font-size: 1.6rem;
				color: $darkBackground;
			}
		}
	}
