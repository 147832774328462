.HeadingWihRightButton{
	display: block;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 3rem !important;
	text-align: center;
	@media(min-width: 561px){
		display: flex;
	}
	h2{
		font-size: 3rem;
		@media(max-width: 560px){
			margin-bottom: 2rem;
		}
	}
	.ButtonWrapper{
		a{
			margin-left: 10px;
			margin-right: 10px;
			@media(max-width: 347px){
				margin-left: 2px;
				margin-right: 2px;
			}
			&:first-child{
				margin-left: 0;
			}
			&:last-child{
				margin-right: 0;
			}
			svg{
				width: 15px;
				height: 15px;
				fill: $white;
				margin-right: 5px;
				margin-top: -3px;
				@include Ease(all, 0.25s);
			}
			&:hover{
				svg{
					fill: $red;
				}
			}
		}
		.RoundButton{
			@media(max-width: 560px){
				padding: 12px 16px;
				font-size: 14px;
			}
			@media(max-width: 363px){
				padding: 12px 13px;
			}
			@media(max-width: 347px){
				font-size: 13px;
			}
		}
		.RoundButtonTransparent{
			@media(max-width: 560px){
				padding: 12px 16px;
				font-size: 14px;
			}
			@media(max-width: 347px){
				padding: 12px 13px;
				font-size: 13px;
			}
		}
	}
}