.CardsContainer {
    .Card {
        padding-top: 30px;
        position: relative;
    }

    &.SlopedCardsTextwithImg {
        .Card {
            .Image {
                img {
                    width: 100%;
                }
            }

            .BGLayer {
                position: relative;
                height: 150px;
                background: $white;
                z-index: 2;

                &:before {
                    content: '';
                    position: absolute;
                    top: -25px;
                    left: 0;
                    right: 0;
                    height: 100%;
                    width: 100.1%;
                    background: $white;
                    transform: skewY(-10deg);
                }
            }

            .Toplayer {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 80%;
                padding: 20px;
                background: $white;
                @include CardShadow();
                @include Ease(background, 0.35s);
                z-index: 3;

                &:before {
                    content: '';
                    position: absolute;
                    top: -25px;
                    left: 0;
                    right: 0;
                    height: 100%;
                    background: $white;
                    transform: skewY(-10deg);
                    @include Ease(background, 0.35s);
                    z-index: -1;

                    @media (min-width: 768px) {
                        top: -20px;
                    }
                    @media only screen and (max-width: 1200px) {
                        top: -38px;
                    }
                    @media only screen and (max-width: 640px) {
                        top:-55px;
                    }
                }

                .Contents {
                    text-align: left;
                    @include Ease(color, 0.35s);

                    .CircleSvg {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        width: 65px;
                        @include Ease(all, 0.35s);

                        &:before {
                            content: '';
                            background-color: transparent;
                            width: 65px;
                            height: 65px;
                            border-radius: 50%;
                            position: absolute;
                            left: 0;
                            transform: scale(0.4);
                            @include Ease(all, 0.35s);
                        }
                    }

                    svg {
                        width: 50px;
                        height: 50px;
                        fill: $base;
                        // stroke: $base;
                        position: relative;
                        @include Ease(all, 0.35s);
                    }

                    h3 {
                        font-family: $primaryFont;
                        font-size: 15px;
                        margin: 12px 0;
                        @include Ease(all, 0.35s);
                    }

                    p {
                        margin-bottom: 0;
                    }
                }
            }

            .uk-card {
                &:hover {
                    .Toplayer {
                        background: $red;

                        &:before {
                            background: $red;
                        }

                        .Contents {
                            color: $white;

                            .CircleSvg {
                                &:before {
                                    background-color: $white;
                                    transform: scale(1);
                                }

                                svg {
                                    transform: scale(0.78);
                                }
                            }

                            svg {
                                fill: $white;
                                // stroke: $white;
                            }

                            h3 {
                                color: $white;

                                &:after {
                                    background-color: $white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.TwoSideBendCardswithImg {
        .uk-grid-small {
            margin-left: 0;
        }
        a {
            text-decoration: none;
            color: inherit;
            height: 100%;
        }

        .Card {
            .Image {
                img {
                    width: 100%;
                }
            }

            .ContentsPart {
                position: relative;
                padding-top: 20px;
                margin-top: -20px;
                @include Ease(all, 0.3s);

                .Curve {
                    position: absolute;
                    width: 54%;
                    height: 50px;
                    background: $white;
                    border-top: 8px solid $red;
                    @include Ease(all, 0.3s);

                    &.LeftCurve {
                        left: 0px;
                        top: -7px;
                        transform: rotate(-12deg);
                    }

                    &.RightCurve {
                        right: 0px;
                        top: -7px;
                        transform: rotate(12deg);
                    }
                }

                .IconwithRoundedBG {
                    position: absolute;
                    left: 50%;
                    top: -55px;
                    transform: translateX(-50%);
                    z-index: 2;
                    border: 2px solid transparent;
                    @include Ease(all, 0.3s);

                    svg {
                        width: 45px;
                        height: 45px;
                        fill: unset;
                        stroke: unset;
                        transform: scale(0.85);
                        @include Ease(all, 0.3s);
                    }
                }

                .Contents {
                    position: relative;
                    padding: 20px 20px;
                    z-index: 1;
                    background: $white;
                    color: $base;
                    @include Ease(all, 0.3s);
                    text-align: center;

                    h3 {
                        margin-bottom: 1rem;
                        font-size: 16px;
                        @include Ease(all, 0.3s);
                    }

                    p {
                        font-size: 14px;
                        color: #444;
                        @include Ease(all, 0.3s);
                    }

                    .MoreLink {
                        margin: 0;
                        font-weight: 700;
                        font-size: 12px;
                    }
                }
            }

            .uk-card {
                position: relative;
                overflow: hidden;
                @include CardShadow();
                @include Ease(all, 0.3s);
                height: 100%;
                display: flex;
                flex-direction: column;
                .Image {
                    display: flex;
                    flex: 1;
                }
                &:hover {
                    .Curve {
                        background: $red;
                        border-color: $white;
                    }

                    .IconwithRoundedBG {
                        background: #d8d8d8;
                        border: 2px solid $white;

                        svg {
                            transform: scale(1);
                        }
                    }

                    .Contents {
                        background: $red;
                        color: $white;

                        h3 {
                            color: $white;
                        }

                        p {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}

.IconwithRoundedBG {
    width: 80px;
    height: 80px;
    background: $white;
    border-radius: 50%;
    margin: 0 auto;
    @include FlexCenter();
    @include CardShadow();
    @include Ease(all, 0.5s);
    z-index: 2;

    svg {
        width: 30px;
        height: 30px;
        fill: $base;
        stroke: $base;
        @include Ease(0.5s);
    }

    &.RedBG {
        background: $red;

        svg {
            fill: $white;
            stroke: $white;
        }
    }

    &.BGLightGray {
        background: #fff !important;
        border: 1px solid #d6d6d6;
        position: relative;

        @include Ease(all, 0.25s);

        &:after {
            content: '';
            width: 110%;
            height: 110%;
            border-radius: 50%;
            background: #f4f0f0;
            position: absolute;
            // left: 50%;
            // top: 50%;
            opacity: 0;
            transform: scale(0);
            border: 9px solid transparent;
            z-index: -1;
            @include Ease(all, 0.4s);
        }

        svg {
            stroke: unset;
            width: 43px;
            height: 43px;
            transform: scale(0.8);
            @include Ease(all, 0.25s);
        }

        &:hover {
            box-shadow: unset;

            &:after {
                opacity: 1;
                transform: scale(1);
                border-color: rgba(251, 251, 251, 0.7686274509803922);
            }

            svg {
                transform: scale(1);
            }
        }
    }
}
