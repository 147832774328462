.select {
    color: $inputColor;
    border-radius: 3px;
    // border: none;
    height: auto;
    width: 100%;

    >.select:first-of-type {
        margin-right: 30px;
    }

    .selectize-input {
        padding: 15px;
        border: 1px solid #f4f0f0;
        box-shadow: none;
        background: $inputBackground;
        outline: none;
        font-size: 16px;

        >input {
            // width: 100% !important;
            font-size: 16px;
            color: $inputColor;

            &::placeholder {
                color: $inputColor;
                font-size: 16px;
            }
        }

        &::placeholder {
            color: $inputColor;
            font-size: 16px;
        }
    }

    .selectize-dropdown {
        border: none;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
        font-size: 15px;
        color: $inputColor;
        cursor: pointer;
    }

    .selectize-dropdown [data-selectable].option {
        padding: 15px;
    }

    .selectize-dropdown-content {
        &::-webkit-scrollbar {
            width: 6px;
            background-color: rgba($lightGrey, .1);
            border-radius: 3px;
            // height: 12px;
        }

        &::-webkit-scrollbar-track {
            background: rgba($lightGrey, .1);
            // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            border-radius: 3px;

        }

        &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            background: $red;
        }
    }
}

.multiselect {
    color: $inputColor;
    border-radius: 3px;
    // border: 1px solid #f4f0f0;
    height: auto;
    width: 100%;

    >.select:first-of-type {
        margin-right: 30px;
    }

    .selectize-input {
        padding: 15px;
        border: 1px solid #f4f0f0;
        box-shadow: none;
        background: $inputBackground;
        font-size: 16px;
        outline: none;

        >input {
            // width: 100% !important;
            font-size: 16px;
            color: $inputColor;
        }

        &::placeholder {
            color: $inputColor;
            font-size: 16px;
        }
    }

    .selectize-dropdown {
        border: none;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
        font-size: 15px;
        color: $inputColor;
        cursor: pointer;
    }

    .selectize-dropdown [data-selectable].option {
        padding: 15px;
    }
}

.uk-select {
    &:not([multiple]):not([size]) {
        // height: auto;
    }

    color: $inputColor !important;
    border-radius: 3px;
    // border: none;
    height: auto;
    width: 100%;
    padding: 15px;
    border: 1px solid #f4f0f0;
    // border: none;
    box-shadow: none;
    // background: $lightBackground;
    border: none;
    width: 100% !important;
    font-size: 16px;
    -webkit-appearance: button;
    -moz-appearance: button;
    -webkit-user-select: none;
    -moz-user-select: none;
    -webkit-padding-end: 20px;
    -moz-padding-end: 20px;
    -webkit-padding-start: 2px;
    -moz-padding-start: 2px;

    &::-ms-expand {
        display: none;
    }

}

.UpdateFormDataSection{
    .select{
        .selectize-input{
            padding: 12px 32px 12px 10px;
        }
        .option{
            cursor: pointer;
            @include Ease(0.1s);
            &:hover{
                background: #e6eeef;
            }
        }
    }
}