.Thumb {
    &__WithContent {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
    }

    &__Image {
        display: block;
        width: 100%;
        max-width: 100%;
        flex: 0 0 33.33%;

        img {
            max-width: 100%;
            height: auto;
            width: 100%;
        }
    }

    &__RightContent {
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 0 0 66.66%;
        width: 100%;

        &--Top {
            display: flex;
            justify-content: space-between;
            @media(min-width: 500px){
                justify-content: flex-start;
            }
            h2 {
                margin-right: 30px;
                font-size: 2rem;
                @media(min-width: 500px){
                    font-size: 2.5rem;
                }
            }

            a {
                margin-top: 0;
                transition: all 0.25s ease-in;
                &:hover{
                    color: $red;
                }
            }
        }

        .Paragraph {
            margin-top: 1rem;
        }
        .ReadMore {
            a {
                font-size: 13px;
                color: $red;
                svg {
                    width: 17px;
                    height: 17px;
                    font-weight: 600;
                }
            }
            
        }
        .ActionButtons {
            display: flex;
            justify-content: space-between;
            width: 100%;
            ul {
                margin: 0;
                padding: 0;
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
                li {
                    &:first-child {
                        margin-right: 20px;
                    }
                    a {
                        font-size: 14px;
                        transition: all .4s;
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        svg {
                        width: 17px;
                        height: 17px;
                        margin-right: 10px;
                        fill: #666666;
                        }
                        &:hover {
                            color: $red;
                        }
                    }
                    
                }
            }
            @media only screen and (max-width: 480px) {
                flex-direction: column;
                .Button {
                    a {
                        margin-top: 1.5rem;
                        display: inline-block;
                    }
                }
            }
        }
    }


}

@media only screen and (max-width:600px) {
    .Thumb {
        &__WithContent {
            flex-direction: column;

            a {
                display: block;
            }
        }

        &__RightContent {
            margin-left: 0;
            margin-top: 20px;
        }
    }
}