.SubHeaderMainWrapper{
	background-color: $darkBackground;
	// padding: 2rem 0;
	border-radius: unset;
	.SubHeaderUl{
		margin-bottom: 0;
		color: $white;
		display: flex;
		padding-left: 0;
		max-width: 100%;
		overflow-x: auto;
		white-space: nowrap;
		li{
			padding-left: 20px;
			padding-right: 20px;
			a{
				font-size: 1.5rem;
				position: relative;
				padding: 1rem 0;
				@include Ease(all, 0.3s);
				@media(min-width: 500px){
					font-size: 1em;
					padding: .8em 0;
				}
				&:before{
					content: '';
					position: absolute;
					bottom: 10px;
					left: 0;
					width: 0;
					height: 1px;
					background-color: $white;
					@include Ease(all, 0.3s);
				}
				&:hover{
					color: $white;
					&:before{
						width: 100%;
					}
				}
				&.is_Active{
					&:before{
						content: '';
						position: absolute;
						bottom: 10px;
						left: 0;
						width: 100%;
						height: 1px;
						background-color: $white;
						@include Ease(all, 0.3s);
					}
				}
			}
			&:first-child{
				padding-left: 0;
			}
			&:last-child{
				padding-right: 0;
			}
		}
	}

}

.SubHeaderUl::-webkit-scrollbar{
	width: 1rem;
	height: 0.6rem;
}
.SubHeaderUl::-webkit-scrollbar-track{
	border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.SubHeaderUl::-webkit-scrollbar-thumb{
	border-radius: 10px;
	background-color: #999;
	outline: 1px solid slategrey;
}
.SubHeaderUl::-webkit-scrollbar:vertical{
	display: none;
}