.Section {
    padding-top: 2rem;
    padding-bottom: 2rem;

    @media screen and (min-width: 768px) {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    &.Continue {
        padding-bottom: 0;
    }

    &.InContinue {
        padding-top: 0;
    }
}


.SmallContainer {
    max-width: 800px;

    &.Center {
        margin-left: auto;
        margin-right: auto;
    }

    @media (min-width: 992px) {}

    @media (min-width: 768px) {}

    @media (max-width: 768px) {
        margin-left: 20px;
        margin-right: 20px;
    }


}

.XSmallContainer {
  max-width: 600px;
  &.Center {
    margin-left: auto;
    margin-right: auto;
  }
}

.SkewedSection {
  transform: skewY(-5deg);
  @media (min-width: 768px) {
    margin-top: 70px;
    margin-bottom: 70px;
  }
  & > div {
    transform: skewY(5deg);
  }
}
