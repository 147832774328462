.dateInput {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: relative;

    &::after {
        content: " ";
        display: block;
        clear: left;
        right: 15px;
        top: 55%;
        transform: translateY(-50%);
        width: 15px;
        height: 9px;
        background-image: url(../img/arrow-new.svg);
        background-size: contain;
        background-position: 50%;
        background-repeat: no-repeat;
        border: none;
        transition: all .2s linear;
        display: block;
        position: absolute;
        // top: 50%;
        right: 15px;
        margin-top: -3px;
        z-index: 2;
    }

    &:active,
    &:visited {
        &::after {
            transform: rotate(-180deg);
            top: 45%;
        }
    }

    .formItem {
        flex: 0 0 90%;
        flex-grow: 1;
        padding: 12px 15px;
        height: 45px;
        box-shadow: none;
        background: $lightBackground;
        color: $darkBackground;
        border: 1px solid darken($lightBackground, 10%);
        display: inline-block;
        width: 100%;
        overflow: hidden;
        position: relative;
        z-index: 1;
        box-sizing: border-box;
        // box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        font-size: 16px;


    }
}

// .icon {
//     margin-left: 12px;
//     display: inline-block;
//     width: 30px;
//     height: 30px;
//     stroke-width: 0;
//     stroke: currentColor;
//     fill: currentColor;
//     align-self: center;
// }