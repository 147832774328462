.HeadingWithLefttIcon {
    .headingBox {
        flex: 1;
    }
}

.TextWithSwitcher {
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 27%;
    vertical-align: bottom;

    label {
        font-weight: 700;
        font-size: 1.6rem;
        color: #333;
        padding-bottom: 0;
        vertical-align: middle;

        &:last-child {
            margin-left: auto;
        }
    }
}