.UploadFileMain {
    background-color: $lightBackground;
    border: 2px dashed $primaryColor;
    padding: 2rem 2rem;

    button {
        padding-left: 4rem;
        padding-right: 4rem;
    }


    .customUploadText {
        margin-left: 10px;
        font-family: sans-serif;
        color: #aaa;
    }
}

.UploadFileMainbox {
    border: 2px dashed $primaryColor;
    padding: 30px;

    .RoundButtonTransparent {
        margin-right: 2rem;

        &:hover {
            .uk-link {
                color: $white;
            }
        }

        .uk-link {
            @include Ease(all, 0.3s);
            color: $red;
        }
    }

    &#uploader {
        .uploadDoc {
            position: relative;
        }

        .docErr {
            position: absolute;
            right: auto;
            left: 10px;
            top: -42px;
            padding: 10px;
            font-size: 15px;
            background-color: $white;
            color: red;
            box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.2);
            display: none;
        }

        .fileUpload {
            position: relative;
            overflow: hidden;
            margin: 10px;

            img {
                width: 30px;
            }

            .upl {
                // font-weight: 600;
                // color: $black;
            }

            input.upload {
                position: absolute;
                top: 0;
                right: 0;
                margin: 0;
                padding: 0;
                font-size: 20px;
                cursor: pointer;
                opacity: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}
