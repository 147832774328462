.CommentsFormSection {
    .NoCommentsContainer {
        padding: 20px 0;
        .CommentsBody {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }
    }
    .CommentsContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .CommentsBody {
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            .CommentsInfo {
                display: flex;
                align-items: center;

                @media only screen and (max-width:600px) {
                    flex-wrap: wrap;
                }

                img {
                    border-radius: 0;
                    max-width: 60px;
                    width: 60px;
                    height: 60px;
                    object-fit: cover;
                }

                .CommentsInfoTop {
                    margin-left: 15px;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-right: 15px;

                    span {
                        font-weight: 700;

                        &.date {
                            font-weight: normal;
                            color: $lightGrey;
                            font-size: 14px;
                        }

                    }
                }

                .CommentsFlag {
                    font-weight: 600;

                    @media only screen and (max-width:600px) {
                        margin-top: 10px;
                    }

                    label {
                        font-size: 16px;
                        margin-right: 10px;
                        color: $base;
                        word-spacing: 2px;
                    }

                    .uk-checkbox {
                        border-radius: 100px;
                        border: none;
                        width: 20px;
                        height: 20px;
                    }
                }
            }

            .CommentsText {
                padding: 15px 0;
                max-width: 80%;

                @media only screen and (max-width:600px) {
                    max-width: 100%;
                }

                p {
                    font-size: 14px;
                    line-height: 1.6;
                }
            }
        }

        .CommentsReply {
            form {

            display: flex;
            justify-content: space-between;
            width: 100%;
            flex-wrap: wrap;
            @media(max-width: 500px){
                display: block;
            }

            input {
                flex: 1;
                border: none;
                padding: 8px;
                font-size: 15px;
                padding-left: 25px;

                @media only screen and (max-width:500px) {
                    // margin-bottom: 10px;
                    width: 100%;
                }

                &::placeholder {
                    color: $lightGrey;
                    font-size: 15px;
                    line-height: 1;
                }
            }

            button {
                align-self: stretch;
                margin-left: auto;

                &.SquareButton {
                    padding: 7px 22px;
                    margin-top: 10px;
                    font-size: 1.45rem;
                    font-weight: 400;
                    @media(min-width: 500px){
                       padding: 10px 40px;
                       margin-top: 0;
                       font-size: 1.6rem;
                       font-weight: 700;
                    }
                }
            }
            }
        }

        &.NestedCommentsContainer {
            padding-left: 50px;
            padding-top: 20px;

            @media only screen and (max-width:600px) {
                padding-left: 25px;
             }
        }
    }

    ul.Pagination {
        justify-content: center;
        margin-top: 50px;
    }
}

.RecentCommentReccentFolowwer{
    h2{
        margin-bottom: 2rem;
        font-size: 3rem;
    }
    .CommentsFormSection{
        padding: 2rem 2rem;
        margin-bottom: 2rem;
        @media(min-width: 769px){
            margin-bottom: 0;
        }
        .RoundButtonTransparent{
            margin-top: 1rem;
        }
    }
}