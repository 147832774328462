.followersContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .followersDetail {
        flex: 0 0 24%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid rgba($lightGrey, .1);
        padding: 12px 0;

        img {
            max-width: 50px;
            border-radius: 100%;
            margin-right: 15px;
        }

        span {
            font-weight: 700;
        }

        @media only screen and (max-width: 900px) {
            flex: 0 0 30%;
        }

        @media only screen and (max-width: 600px) {
            flex: 0 0 48%;
            flex-direction: column;

            span {
                padding-top: 5px;
                font-size: 1.4rem;
                @media(min-width: 500px){
                    font-size: 1.6rem;
                }
            }
        }
    }
}
.NoFollowersContainer {
    padding: 20px 0;
    .FollowersBody {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}