//----------------------------------*\
// CONFIG MODULES
//----------------------------------*/
html, body  {
	width: 100%;
	min-height: 100%;
	padding: 0 !important;
	margin: 0 !important;
}

@import 'modules/_vars';
@import 'modules/Mixins';
@import 'modules/_clearfix';
@import 'modules/_defaults';
@import 'modules/_print';
@import 'modules/Fonts';
@import 'modules/uikit-custom';

//----------------------------------*\
// PARTIALS
//----------------------------------*/

@import 'partials/SelectizeCustom';
@import 'partials/Buttons';
@import 'partials/Switcher';

@import 'partials/Buttons';
@import 'partials/_header';
@import 'partials/_nav';
@import 'partials/_footer';
@import 'partials/Sections';
@import 'partials/Sidebar';
@import 'partials/Sliders';
@import 'partials/Cards';
@import 'partials/_FilterSidebar';
@import 'partials/RecentGallerySection';

// Digember
@import 'partials/Select';
@import 'partials/Banners';
@import 'partials/HomeForm';
@import 'partials/ThumbWithContent';
@import 'partials/Pagination';
@import 'partials/HeaderWithButtons';
@import 'partials/CommentsForm';
@import 'partials/FollowersGrid';
@import 'partials/SettingsPage';
@import 'partials/UploadPhotoForm';
@import 'partials/Date';
@import 'partials/GalleryWithContentModal';
@import 'partials/NormalPageContent';
@import 'partials/FaqAccordian';
@import 'partials/LeadsPage';

//Shahid
@import 'partials/customerOrProfessionalModal';
@import 'partials/RegisterFormModal';
@import 'partials/Form';
@import 'partials/EmailVerificationModal';
@import 'partials/Modal';
@import 'partials/VerificationSuccessModal';
@import 'partials/CompleteYourProfile';
@import 'partials/FirmDetailsForm';
@import 'partials/FirmLogoUpload';
@import 'partials/UpdateFormData';
@import 'partials/professional-dashboard';
@import 'partials/SubHeader';
@import 'partials/LeftIconRightTextWithButton';
@import 'partials/StatisticsBox';
@import 'partials/headingWithrightBotton';
@import 'partials/LeftImageRightTextCard';
@import 'partials/RecentFollowersList';
@import 'partials/SignInSignUpModal';
@import 'partials/NotificationModal';
@import 'partials/ContactFirmModl';
@import 'partials/FirmProfile-TinyMCE';
@import 'partials/NotFound';

//----------------------------------*\
// PAGE
//----------------------------------*/

@import 'page/Home';
@import 'page/SearchResultPage';
@import 'page/ProfessionalCategory';

// body.hideHeader {
// 	header {
// 		top: -100%;
// 	}
// }

.SuccessMessage {
	padding: 16px 16px;
	font-weight: bold;
	background: #009900;
	color:#fff;
	border-radius: 4px;
	margin-bottom: 16px;
	a {
		text-decoration: underline;
	}
}
.WarningMessage {
	padding: 16px 16px;
	font-weight: bold;
	background: #990000;
	color:#fff;
	border-radius: 4px;
	margin-bottom: 16px;
	a {
		text-decoration: underline;
	}
}
.ProjectCard {
	padding: 0 8px;
	.uk-card:hover {
		&.uk-card-hover {
			background: #fff;
		}
	}
	.uk-card-media-top {
		img {
			width: 100%;
		}
	}
	.uk-card-body {
		padding: 20px;
	}
	.uk-card-footer {
		padding: 10px 20px;
	}
	.LikeCommentbox {
		text-align: left;
		margin: 0;
		padding: 0;
		li {
			display: inline-block;
			padding-right: 18px;
			font-size: 12px;
			vertical-align: middle;
			span {
				vertical-align: middle;
				margin-bottom: 0;
			}
			span svg {
				width:20px;
				margin: 0 4px 0 0;
				height:20px;
			}
		}
	}
	ul.Description {
		padding: 0;
		margin: 0;
		box-sizing: border-box;
		li.Name {
			font-size: 16px;
			font-weight:bold;
		}
		li.Category {
			font-size: 14px;
			color: #666;
		}
		li.Professional {
			display: flex;
			align-items: flex-start;
			margin-top: 8px;
			padding-top:8px;
			border-top: #f4f0f0 thin solid;
			font-size: 12px;
			img {
				width: 30px;
				height: 30px;
				border-radius: 50%;
				display: inline-block;
				border: #f4f0f0 thin solid;
				margin-right: 8px;
			}
			.BoldPara{
				font-size: 1.35rem;
				font-weight: 600;
				margin-bottom: 0.35rem;
			}
		}
	}
}

img.currentImage {
	width:100px;
	height: auto;
	padding: 4px;
	border: #dedede thin solid;
}

div.NoContent {
	padding: 70px;
	background: #fdfdfd;
	border: #f0f0f0 thin dashed;
	text-align: center;
	margin: 1em 0;
	color: #999;
}

.Tabs {
	padding: 1em 0;
	background: #fff;
	margin: 1em 0 0;
	border-bottom:none;
	font-size: 1em;
	&:before {
		display: none;
	}
	&.uk-tab>*>a {
		font-size: 1em;
	}
	&.uk-tab>.uk-active>a {
		border-color: $red;
		font-weight: bold;
	}
}

.TabContent {
	margin-top: 0 !important;
}