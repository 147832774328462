.VerificationSuccessModal{
	.uk-modal-body{
		padding-top: 4.5rem;
		padding-bottom: 4.5rem;
	}
	.headingBox{
		width: 100%;
		margin: 2rem auto 0;
		@media(min-width: 500px){
			width: 60%;
		}
		h2{
			font-weight: 400;
		}
		h5{
			font-size: 1.5rem;
			color: $lightGrey;
		}
	}
	.RoundButton{
		padding-left: 4rem;
		padding-right: 4rem;
		&.M-Top2{
			margin-top: 2rem;
		}
	}
}