.HeadingWithLefttIcon {
    display: flex;
    align-items: center;

    &.BGRed {
        color: $white;
    }

    .IconBox {
        background-color: $red;
        width: 65px;
        height: 65px;
        border-radius: 50%;
        box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.2);
        @include FlexCenter;

        @media(min-width: 500px) {
            width: 80px;
            height: 80px;
        }

        svg {
            fill: $white;
            width: 35px;
            height: 35px;
            margin-bottom: 0;

            @media(min-width: 500px) {
                width: 40px;
                height: 40px;
            }
        }
    }

    .headingBox {
        margin-left: 0;

        // width: calc(100% - 88px);
        @media(min-width: 500px) {
            // width: calc(100% - 135px);
            margin-left: 15px;
        }
        h1 {
            font-size: 2.2rem;
        }
        h2 {
            font-size: 2.2rem;
        }

        h5 {
            font-weight: 400;
            font-size: 1.6rem;
            color: $darkBackground;
        }
    }
}

.HeadingWithTopIcon {
    .IconBox {
        background-color: $red;
        width: 65px;
        height: 65px;
        border-radius: 50%;
        box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.2);
        margin: 0 auto;
        @include FlexCenter;

        @media(min-width: 500px) {
            width: 80px;
            height: 80px;
        }

        svg {
            fill: $white;
            width: 35px;
            height: 35px;
            margin-bottom: 0;

            @media(min-width: 500px) {
                width: 40px;
                height: 40px;
            }
        }
    }

    .headingBox {
        margin-left: 15px;
        width: 100%;
        h1 {
            font-size: 2.2rem;
        }
        h2 {
            font-size: 2.2rem;
        }

        h5 {
            font-weight: 400;
            font-size: 1.6rem;
        }
    }
}

.ModalHeadingWrapper {
    &.BGRed {
        color: $white;

        .HeadingWithLefttIcon {
            .IconBox {
                background-color: $white;

                svg {
                    fill: $black;
                }
            }
        }
    }
}

.WhiteColor {
    &.uk-close {
        color: $white;
        @include Ease(all, 0.25s);

        &:hover {
            color: $white;
            opacity: 0.7;
        }
    }
}

.uk-modal {
    z-index: 999;
}

.uk-modal-body {
    border-radius: 6px;
}

.uk-close {
    color: $red;

    &:hover {
        color: darken($red, 15%);
    }

    svg {
        width: 20px;
        height: 20px;
    }
}



.uk-card-body {
    border-radius: 6px;
}