.SignInSignUpModal{
	.uk-modal-body{
		padding: 0;
	}
	.uk-modal-close-default{
		color: $red;
		@media(min-width: 640px){
			color: $white;
		}
	}
	&.OnPage {
		background: $white;
		border-radius:4px;
		max-width:800px;
		box-shadow: 0px 2px 6px rgba(0,0,0,.15);
	}
	&.Lefty {
		.SignInContainer {
			text-align: left;
		}
	}
	.SignInContainer{
		padding: 3rem 4rem;
		text-align: center;
		form{
			.uk-form-controls{
				text-align: left;
				margin-bottom: 1.2rem;
				span{
					font-size: 1.45rem;
				}
			}
		}
	}
	.SocialMediaContainer{
		padding-bottom: 1.5rem;
		padding-top: 1.5rem;
		display: flex;
		justify-content: center;
		.IconButton{
			margin-left: 8px;
			margin-right: 8px;
		}
	}
	.ModlRightSide{
		border-radius: 0 0px 6px 6px;
		padding-left: 30px;
		@media(min-width: 640px){
			padding-left: 0;
			border-radius: 0 6px 6px 0;
		}
	}
	.SignUpContainer{
		color: $white;
		padding: 3rem 3rem;
		text-align: center;
		display: flex;
	    align-items: center;
	    height: 100%;
	    border-radius: 0 0px 6px 6px;
	    @media(min-width: 640px){
			border-radius: 0 6px 6px 0;
	    }
		h3{
			color: $white;
			margin-bottom: 1rem;
		}
		p{
			a{
				text-decoration: underline;
				@include Ease(all, 0.25s);
				&:hover{
					color: $white;
					font-weight: 600;
				}
			}
		}
	}
}

