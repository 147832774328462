.Footer {
    text-align: center;
    background: $darkBackground;
    position: relative;
    z-index: 9;
    overflow: hidden;
    .SocialSticky {
        padding: 0px;
        margin: 0px;
        position: fixed;
        left: -130px;
        bottom: 50px;
        width: 165px;
        z-index: 1100;
        .socialLeft {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                margin-bottom: 2px;
                list-style-type: none;
                background-color: #fff;
                border: 1px solid #666;
                border-radius: 0 30px 30px 0;
                color: #efefef;
                height: 45px;
                padding: 0;
                margin: 5px 0px 1px 0px;
                transition: all .25s ease-in-out;
                cursor: pointer;
                // @media only screen and max-width: 
                &:hover {
                    margin-right: -145px;
                }
                a {
                    display: block;
                }
                span {
                    float: right;
                    margin: 2px 6px;
                    margin-right: 0px;
                    margin-left: 0px;
                    background: #fff;
                    padding: 10px;
                    border-radius: 50%;
                    font-size: 20px;
                    transform: rotate(0deg);
                    display: inline-block;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    svg {
                        width: 20px;
                        height: 20px;
                        fill: $black;
                        display: inline-block;
                    }
                }
                p {
                    text-align: right;
                    padding-top: 5px;
                    margin: 0px;
                    color: #000;
                    line-height: 16px;
                    font-size: 11px;
                    text-decoration: none;
                    font-weight: bold;
                    white-space: pre-wrap;

                }
            }
        }
    }

    &__TopSection {
        background: #1b1a1a;
        padding: 30px 0;

        >div {
            // transform: skewX(20deg);
            display: flex;
            max-width: 800px;
            justify-content: space-between;
            margin: 0 auto;
            align-items: center;
            @media(max-width: 500px){
                align-items: unset;
            }

            h5 {
                font-size: 2.3rem;
                color: $white;
                // margin: auto 0;
            }
            .NewsletterInputDiv{
                flex: 1;
                margin-left: 30px;
                @media(max-width: 500px){
                    margin-left: 0;
                }
                .text-danger{
                    text-align: left;
                }
            }

            .SubscribeForm {
                // flex: 1;
                display: flex;
                // margin-left: 30px;
                overflow: hidden;
                box-shadow: 1px 3px 4px rgba(0, 0, 0, .1);

                input {
                    border: none;
                    padding: 20px;
                    width: 100%;
                    outline: none;
                    color: $lightGrey;
                    font-family: $primaryFont;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    @media(max-width: 500px){
                        padding: 15px 20px;
                    }
                }

                input[placeholder] {
                    color: $lightGrey;
                    font-size: 14px;
                }

                div {
                    position: relative;
                    overflow: hidden;
                    background: $red;
                    border-radius: 0 4px 4px 0;
                    margin-left:-8px;
                    width:70px;
                    flex-basis: 70px;
                    transition: background .3s ease;
                    @media(max-width: 500px){
                        background: darken($red, 15%);
                    }
                    a {
                        width:70px;
                        height:50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        svg {
                            width:28px;
                            height:28px;
                            margin-top:8px;
                            margin-left:-4px;
                            @media(max-width: 500px){
                                width:25px;
                                height:25px;
                                margin-top:5px;
                            }
                        }

                    }
                    &:hover {
                        background: darken($red, 15%);
                    }
                }


            }
        }
        .SmallContainer{
            position: relative;
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    &__BottomSection {
        display: flex;
        width: 100%;
        justify-content: space-between;
        color: $primaryColor;
        align-items: flex-start;
        margin-top: 4rem;
        text-align: left;

        >div {
            display: block;
            max-width: 270px;

            a {
                display: inline-block;
                text-align: left;
                border-bottom: 1px solid transparent;
                transition: border-bottom .4s;

                &:hover {
                    text-decoration: none;
                    color: $primaryColor;
                    border-bottom: 1px solid rgba($primaryColor, .4);
                }
                &.NotLine{
                    border-bottom: none;
                    &:hover{
                        border-bottom: none;
                    }
                }
            }

            p {
                margin-top: 20px;
                text-align: left;
                font-size: 1.4rem;
                font-family: $primaryFont;
            }

            ul {
                padding: 0;
                margin: 0;
                &.Footer__contactInfo{
                    li{
                        .InfoLinkDiv{
                            display: flex;
                            justify-content: flex-start;
                            svg{
                                margin-right: 1rem;
                                margin-top: 0.5rem;
                            }
                        }
                        .InfoLink{
                            // display: flex;
                            &.InfoNoLink{
                                span{
                                    font-size: 1.4rem;
                                    display: inline-block;
                                    line-height: 1.7;
                                }
                            }
                            .verticalalignDash{
                                padding-left: 0.3rem;
                                padding-right: 0.3rem;
                            }
                            
                            a{
                                display: inline;
                                svg{
                                    margin-right: 0.5rem;
                                }
                            }
                        }
                    }
                }

                li {
                    margin-top: 1rem;

                    a {
                        font-size: 1.4rem;
                        transition: all 0.25s ease-in;
                        &:hover{
                            color: $white;
                        }
                    }
                }
            }

            h6 {
                font-size: 1.8rem;
                margin: 1rem 0 2rem;
                color: $white;
                font-family: $primaryFont;
            }
        }
    }

    &__contactInfo {
        li {
            a {
                display: flex;
                justify-content: flex-start;

                svg {
                    margin-top: .5rem;
                    margin-right: 1rem;
                }

                span {
                    line-height: 1.7;
                }
            }
        }

    }

    &__icon {
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        stroke-width: 0;
        stroke: currentColor;
        fill: currentColor;
    }

    .FooterBottom {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        text-align: left;
        color: $primaryColor;
        padding-bottom: 1.5rem;
        font-size: 12px;
        p {
            flex: 1;
        }
        a{
            transition: all 0.25s ease-in;
            &:hover{
                color: $white; 
            }
        }
    }

    hr {
        opacity: .4;
    }
}

@media only screen and (max-width:900px) {
    .Footer {

        &__TopSection {
            background: $darkBackground;
            margin-left: 0;
            margin-right: 0;
            // transform: none;
            position: relative;
            margin-right: -30px;

            .Skewed {
                position: absolute;
                left: 40%;
                top: 0;
                display: block;
                width: 60%;
                height: 100%;
                background: $red;
                clip-path: polygon(40% 0, 100% 0, 100% 100%, 0% 100%);

            }

            >div {
                // transform: none;
                flex-direction: column;
                margin: auto;

                h5 {
                    text-align: left;
                    margin-bottom: 20px;
                }

                .SubscribeForm {
                    margin-left: 0;
                    margin-right: 30px;
                }
            }

        }

        &__BottomSection {
            flex-wrap: wrap;
            margin-top: 2rem;

            >div:first-child {
                flex: 0 0 100%;
                border-bottom: 1px solid rgba($primaryColor, .4);
                margin-bottom: 3rem;
            }

            >div {
                flex: 0 0 50%;
                max-width: unset;

                &:nth-child(-n+5):nth-child(n+4) {
                    margin-top: 1rem;
                }
            }
        }

        &__contactInfo {
            li {
                a {
                    span {
                        word-break: break-word;
                    }
                }
            }
        }

        .FooterBottom {
            flex-direction: column;
            align-items: center;
            padding: 0;
            font-size: 14px;

            span {
                text-align: center;
                background: black;
                padding: 10px;
                margin-left: -30px;
                width: 100%;
                margin-right: -30px;
                a {
                    &:hover {
                    color: $red;
                    }
                }
                
            }
        }
    }
}