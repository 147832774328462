.LeadsContainer {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	width: 100%;
	flex-wrap: wrap;
	.LeadsCard {
		flex: 0 0 49%;
		box-shadow: 0px 4px 7px rgba(0,0,0,.1);
		padding: 12px;
		background-color: #fff;
		border-radius: 3px;
		margin-bottom: 20px;
		transition: all .2s ease-in-out;
		&:hover {
			box-shadow: 1px 7px 14px rgba(0,0,0,.1);
		}
		@media only screen and (max-width: 767px) {
			flex: 0 0 100%;
		}
		.top {
			display: flex;
			justify-content: space-between;
			align-items: baseline;
			flex-wrap: wrap;
			border-bottom: 1px solid $grayLine;
			h3 {
				font-size: 16px;
				
			}
			span {
				font: status-bar;
				svg {
					height: 13px;
			    width: 13px;
			    margin-right: 5px;
				}
			}
			p,span {
				color: #7e7988;
				font-size: 14px;
				font-weight: 600;
			}
		}
		.middle {
			padding: 10px 0 0;
			display: flex;
			justify-content: flex-start;
			align-items: baseline;
			border-bottom: 1px solid $grayLine;
			flex-wrap: wrap;

			span {
				padding: 5px 15px;
				color: #fff;
				background-color: #ed3237;
				border-radius: 2px;
				font-size: 14px;
				margin-right: 12px;
				font-weight: 600;
				margin-bottom: 10px;
				// background-color: #ed3237;
			}
		}
		.description {
			padding: 10px 0 0;
			p {
				color: #7e7988;
				font-size: 15px;
			}
		}
	}
}