.uk-navbar-sticky {
  background-color: $red;
  box-shadow: 0 3px 7px rgba($black, 0.3);
}
.uk-navbar-left {
  z-index: 9999;
  background-color: $white;
  transform: skewX(-25deg);
  @media screen and (min-width: 1024px) {
    margin-left: -30px;
  }
}

.uk-navbar-nav {
  height: 100%;
  > li {
    padding: 0 5px;
    & > a {
      padding: 25px 0;
      height: 100%;
      .MenuBg {
        // background-color: $white;
        transform: skewX(-20deg);
        padding: 10px 15px;
        line-height: 1;
        .MenuLabel {
          font-weight: 600;
          transform: skewX(20deg);
        }
      }
    }
  }
}
.uk-navbar-nav > li:hover > a,
.uk-navbar-nav > li > a:focus,
.uk-navbar-nav > li > a.uk-open {
  .MenuBg {
    background-color: $white;
  }
}

.uk-navbar-nav > li.uk-active > a {
  .MenuBg {
    background-color: $white;
  }
}
.uk-navbar-dropdown {
  background: 0;
  padding: 0;
  .uk-navbar-dropdown-nav {
    font-size: 14px;
  }
  ul {
    background: $white;
    padding: 10px 20px;
    li {
      a {

      }
    }
  }
}

.uk-card-default {
  box-shadow: none;
  position: relative;
}
.uk-card-body {
  padding: 0;
}