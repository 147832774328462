.SectionwithSidebar {
    position: relative;
    &:before {
        position: absolute;
        top:0; left: 0; right: 0;
        height: 108px;
        background: #f4f0f0;
        display: block;
        z-index: -1;
        content: ' ';
    }
    .uk-container {
        padding-top: 32px;
    }
}

.ProfessionalSidebar {
    img.ProfessionalLogo {
        padding: 4px;
        background: #fff;
        border: #f4f0f0 thin solid;
        box-shadow: 0 2px 5px rgba(0,0,0,.15);
    }
    @media only screen and (max-width:600px) {
        img {
            display: block;
            margin: 0 auto;
            width: auto;
        }
    }

    h3 {
        margin-bottom: 10px;
        font-size: 18px;
        font-family: $primaryFont;

        @media only screen and (max-width:900px) {
            font-size: 16px;
        }
    }

    .SidebarButton {
        padding-top: 30px;
        @media only screen and (max-width: 960px) {
            a.ArrowedButton.Block {
                padding: 10px 70px 10px 20px;
            }
        }
        @media only screen and (max-width:682px) and (min-width: 640px){
            a.ArrowedButton.Block{
                font-size: 1.35rem;
            }
            
        }
    }

    .SocialShare {
        padding-top: 30px;
    }

    .DetailsList {
        padding-top: 30px;
        font-size: 14px;
        ul {
            padding: 0;
            margin: 0;

            li {
                display: flex;
                padding: 12px 0;
                border-bottom: solid thin rgba($base, 0.1);

                &.Heading {
                    border-top: solid thin rgba($base, 0.1);

                    h3 {
                        margin: 0;
                    }
                }

                span {
                    &:last-child {
                        margin-left: auto;
                    }
                }

                a {
                    border-bottom: solid thin $black;
                }

                @media only screen and (max-width:900px) {
                    font-size: 14px;
                }
            }
        }
    }
}

.SocialShare {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        
        li {
            display: inline-block;
            // color: $white;

            &:not(:first-child) {
                margin-left: 15px;
                @media only screen and (max-width:700px) and (min-width: 640px){
                    margin-left: 5px;
                }
            }

            .uk-icon {
                padding: 8px;
                border-radius: 30px;
                @include Ease(all, 0.25s);

                svg {
                    color: $white;
                    width: 18px;
                    height: 18px;
                }
            }

            a[uk-icon="facebook"] {
                background: #4267b2;
                &:hover{
                    background: darken(#4267b2, 10%);
                }
            }

            a[uk-icon="instagram"] {
                background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
            }

            a[uk-icon="whatsapp"] {
                background: #25D366;
                &:hover{
                    background: darken(#25D366, 10%);
                }
            }

            a[uk-icon="twitter"] {
                background: #38A1F3;
                &:hover{
                    background: darken(#38A1F3, 10%);
                }
            }

            a[uk-icon="linkedin"] {
                background: #0077B5;
                &:hover{
                    background: darken(#0077B5, 10%);
                }
            }

            @media only screen and (max-width: 700px) {
                // &:last-child {
                //     margin-left: 0;
                // }
            }
        }
    }
}

.ProfessionalMain {

    h1,
    h2 {
        font-size: 2rem;
        line-height: 1.6;
        @media(min-width: 992px){
            font-size: 2.5rem;
        }
    }

    .AlignedTextIcon {
        color: $base;
        @include FlexCenter();
        
        span {
            margin-left: 5px;
            // margin-top: 5px;
            // @include Ease(all, 0.25s);

        }
    }
    .LinkHover{
        color: $base;
        @include Ease(all, 0.25s);

        text-decoration: none;
        svg{
            @include Ease(all, 0.25s);
            &.LikedProjectSVG{
                fill: $red;
            }
        }
        &:hover {
            color: $red;
            svg{
                fill: $red;
            }
        }
        
        span{
            &.LikedProject{
                font-weight: 600;
                color: $red;
            }
        }
        .LinkHoverSpan{
            @include FlexCenter();
            &.LikedProjectLink{
                color: $red;
                font-weight: 600;
                svg{
                    fill: $red;
                }
            }
        }
    }

    .Head {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .LeftPart {
            .Category {
                color: $lightGrey;
                font-weight: 600;
                font-size: 1.5rem;
            }
        }

        .RightPart {
            margin-left: auto;
            flex-direction: row-reverse;
            justify-content: space-between;
            display: flex;
            #follow {
                align-self: center;
            }
            .Follow {
                text-align: center;
                // padding-top: 10px;
                margin-right: 20px;
                align-self: center;

                svg {
                    width: 15px;
                    height: 15px;
                }
            }
        }

        @media only screen and (max-width:640px) {
            margin-top: 30px;
            display: flex;
            flex-direction: column;

            .LeftPart {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;

                .RoundButton.RedBG {
                    flex: 0 0 50%;
                    max-width: 50%;
                }
            }

            .RightPart {
                margin-left: 0;
                display: flex;
                justify-content: flex-start;
                margin-top: 20px;

                .Follow {
                    // margin-left: 15px;
                }
            }
        }
    }

    .ContentWrapperDiv{
        padding: 20px 0;
        border-bottom: solid thin rgba($base, 0.2);
        .MetaInfoContent{
            p:last-child{

                margin-bottom: 0;
            }
        }
    }

    .ProjectStatusWrap{
        padding: 1.5rem 0 0.5rem;
    }

    .MetaInfo {
        padding: 16px 4px;
        border-bottom: solid thin rgba($base, 0.2);

        // .MetaInfoContent{
        //     padding-bottom: 20px;
        // }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            li {
                display: inline-block;
                padding-right: 10px;

                .AlignedTextIcon {
                    font-size: 14px;
                }

                &:not(:first-child) {
                    margin-left: 10px;
                }

                svg {
                    width: 20px;
                    height: 20px;
                }
            }

            @media only screen and (max-width:900px) {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: flex-start;

                li {
                    flex: 0 0 50%;
                    padding-bottom: 8px;
                    padding-right: 0;

                    &:not(:first-child) {
                        margin-left: 0;
                    }

                    span {
                        justify-content: flex-start;
                    }
                }
            }
        }
    }

    &.ProfessionalCategoryMain {
        .ContentWrapper {
            padding: 30px 0;
            border-bottom: solid thin rgba($base, 0.2);

            h2 {
                margin-bottom: 20px;
            }
        }

        .ProjectsContainer {
            padding: 30px 0;

            h2 {
                margin-bottom: 20px;
            }

            .ProjectsWrapper {
                .uk-card {
                    @include Ease();

                    &:hover {
                        @include CardShadow();
                    }

                    .professionalProjectsCard {
                        display: flex;
                        flex-direction: column;

                        img {
                            height: 152px;
                        }
                    }
                }


                .Caption {
                    text-align: center;
                    padding: 10px 0;

                    h3 {
                        font-size: 18px;
                        margin-bottom: 0;
                    }

                    .CategoryName {
                        color: $lightGrey;
                        font-size: 15px;
                        margin: 0;
                    }
                }
            }
        }

        .ContactButton {
            margin-top: 30px;
            text-align: center;
        }
    }

    &.ProfessionalProjectsMain {
        .MetaInfo {
            h2 {
                margin-bottom: 15px;
            }

            .AlignedTextIcon{
                span{
                    // margin-left: 3px;
                }
            }
        }

        .ProjectsGallery {
            padding: 30px 0;

            .ProjectCard {
                .OverlayBlock {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) scale(0);
                    width: calc(100% - 25px);
                    height: calc(100% - 25px);
                    background-color: rgba($black, 0.4);
                    opacity: 0;
                    @include Ease();
                    @include FlexCenter();

                    svg {
                        width: 30px;
                        height: 30px;
                    }
                }

                a {
                    @include Ease();
                    display: flex;
                    flex-direction: column;
                    img {
                        height: 152px;
                    }
                    &:hover {
                        .OverlayBlock {
                            opacity: 1;
                            transform: translate(-50%, -50%) scale(1);
                        }
                    }
                }
            }

            .Category {
                font-size: 1.5rem;
                font-weight: 600;
                margin: 10px 0;
                @media(min-width: 500px){
                    font-size: 1.8rem;
                }
            }
        }

        .CommentsFormSection {
            .CommentsContainer {
                .CommentsBody {
                    padding-top: 20px;

                    .CommentsText {
                        padding-bottom: 0;
                        max-width: 100%;
                    }

                    .CommentsFlag {
                        color: $red;

                        span {
                            svg {
                                width: 20px;
                                height: 20px;
                                @media(min-width: 500px){
                                    width: 25px;
                                    height: 25px;
                                }
                            }
                        }

                        a {
                            vertical-align: -webkit-baseline-middle;
                            font-weight: bold;
                            margin-left: 5px;
                            font-size: 1.5rem;
                            transition: color .4s;
                            @media(min-width: 500px){
                                font-size: 1.8rem;
                            }
                            &:hover {
                                color: $base;
                            }
                        }

                    }
                }

                &.NestedCommentsContainer {
                    padding-top: 0;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        border: thin solid rgba($lightGrey, .2);
                        height: 60px;
                        top: 20px;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        width: 18px;
                        border: thin solid rgba($lightGrey, .2);
                        top: 50px;
                    }
                }
            }
            .load_more_comment{
                padding: 14px 65px 14px 20px;
                @include Ease(all, 0.3s);
                .ArrowBG{
                    width: 20%;
                    .Arrow{
                        left: 10%;
                        transform: rotate(90deg) translateX(-50%);
                        @include Ease(all, 0.3s);
                    }
                }
                &:hover{
                    .Arrow{
                        transform: rotate(90deg) translateX(-35%);
                    }
                }
            }
        }

        .LeaveComments {
            margin-top: 2rem;

            h4 {
                padding: 2rem 0;
                font-size: 2.2rem;
                @media(min-width: 992px){
                    font-size: 2.4rem;
                }
            }

            .LeaveCommentsForm {
                label {
                    color: rgba($lightGrey, .6);
                }

                input {
                    border: none;
                    outline: none;
                    background: $lightBackground;
                }

                textarea {
                    border: none;
                    outline: none;
                    background: $lightBackground;
                }
            }
        }

        .CommentButton {
            margin-top: 30px;
        }
    }
}