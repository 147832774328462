.LeftImageRightTextCard{
	 display: block;
	 background-color: $white;
	 padding: 1.5rem;
	 margin-bottom: 2.5rem !important;
	 @media(min-width: 768px){
	 	display: flex;
	 	padding: 2rem;
	 }
	 &.BlueStrip{
	 	border-right: 8px solid $Blue;
	 	.CardStatus{
	 		h2{
	 			color: $Blue;
	 		}
	 	}
	 }
	 &.RedStrip{
	 	border-right: 8px solid $red;
	 	.CardStatus{
	 		h2{
	 			color: $red;
	 		}
	 	}
	 }
	 &.GreenStrip{
	 	border-right: 8px solid $Green;
	 	.CardStatus{
	 		h2{
	 			color: $Green;
	 		}
	 	}
	 }
	.ImageBox{
		width: 100%;
		margin-bottom: 2rem;
		@media(min-width: 501px){
			width: 290px;
			margin: 0;
		}
		img{
			width: 100%;
		}
	}
	.TextContent{
		flex: 1;
		margin-left: 0;
		@media(min-width: 501px){
			margin-left: 20px;
		}
		.CardHead{
			display: flex;
			justify-content: space-between;
			// align-items: center;
			h2{
				font-size: 1.8rem;
				@media(min-width: 501px){
					font-size: 2.5rem;
				}
			}
			p{
				color: $lightGrey;
			}
			.CardStatus{
				border-left: 1px solid $primaryColor;
				padding-left: 15px;
				p{
					margin-bottom: 0;
				}
			}
		}
	}
	.SocialIconBox{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: 15px;
		ul{
			padding-left: 0;
			margin-bottom: 0;
			margin-top: 0;
		}
		.LikeCommentbox{
			display: flex;
			@media(max-width: 340px){
				flex: 1;
			}
			li{
				margin-right: 15px;
				margin-left: 15px;
				text-align: center;
				font-size: 1.45rem;
				@media(min-width: 501px){
					text-align: left;
					font-size: 1.6rem;
				}
				&:last-child{
					margin-right: 0;
				}
				&:first-child{
					margin-left: 0;
				}
				a{
					color: $darkBackground;
					text-align: center;
					font-size: 1.45rem;
					@media(min-width: 501px){
						text-align: left;
						font-size: 1.6rem;
					}
					&:hover{
						svg{
							fill: $black;
							stroke: $black;
						}
					}
				}
				span{
					margin-right: 3px;
					display: block;
					@media(min-width: 501px){
						display: inline;
					}
				}
				svg{
					fill: $darkBackground;
					stroke: $darkBackground;
					width: 15px;
					height: 15px;
					margin-bottom: 0 !important;
					@include Ease(all, 0.25s);
					@media(min-width: 501px){
						width: 20px;
						height: 20px;
					}
				}
			}
		}
		.DeleteEditBox{
			display: block;
			@media(min-width: 376px){
				display: flex;
			}
			@media(max-width: 340px){
				margin-left: 20px;
			}
			li{
				margin-right: 0;
				margin-bottom: 10px;
				@media(min-width: 376px){
					margin-right: 10px;
					margin-bottom: 0;
				}
				&:last-child{
					margin-right: 0;
				}
				a{
					display: inline-block;
					// width: 28px;
					// height: 28px;
					border-radius: 50px;
					@include FlexCenter;
					&.BGRed{
						border: 1px solid $red;
						@include Ease(all, 0.25s);
						&:hover{
							background: #f3f3f3;
							border: 1px solid $red;
							svg{
								fill: $red;
							}
						}
						svg{
							fill: $white;
							@include Ease(all, 0.25s);
						}
					}
					&.BGGrayBorder{
						&:hover{
							svg{
								fill: $black;
							}
						}
						svg{
							fill: $lightGrey;
							@include Ease(all, 0.25s);
						}
					}
				}
				svg{
					width: 15px;
					height: 15px;
					margin-bottom: 0;
				}
			}

		}
	}
}