.selectize-control.single .selectize-input {
    &:after {
        right: 15px;
        top: 55%;
        transform: translateY(-50%);
        width: 15px;
        height: 9px;
        background-image: url(../img/arrow-new.svg);
        background-size: contain;
        background-position: 50%;
        background-repeat: no-repeat;
        border: none;
        transition: all .2s linear;
        background-color: $inputBackground;
    }

    &.input-active {
        &:after {
            transform: rotate(-180deg);
            top: 45%;
        }
    }
}


.selectize-control.multi .selectize-input {
    &:after {
        right: 15px;
        top: 55%;
        transform: translateY(-50%);
        width: 15px;
        height: 9px;
        background-image: url(../img/arrow-new.svg);
        background-size: contain;
        background-position: 50%;
        background-repeat: no-repeat;
        border: none;
        transition: all .2s linear;
        content: " ";
        display: block;
        position: absolute;
        // margin-top: -3px;
    }

    &.input-active {
        &:after {
            transform: rotate(-180deg);
            top: 45%;
        }
    }

    &.has-items {
        .item {
            padding: 5px 10px;
            color: $white;
            background: $red;
            border-radius: 3px;
            margin-right: 7px;
        }
    }
}