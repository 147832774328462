@mixin Overlay() {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

@mixin Ease($element : all, $time: 0.2s) {
	transition: $element ease-in-out $time;
}

@mixin FlexCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}
@mixin CardShadow {
  box-shadow: 0 3px 10px rgba(0,0,0,0.2);
}
