.WhoJoinMerino {
    .LeftContainer {
        position: relative;
    }

    .BGLayer {
        background: $red;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 10px;
        right: -10px;
        z-index: -1;

        @media screen and (min-width: 768px) {
            top: 30px;
            right: -30px;
        }
    }

    .CategoryIconContainer {
        @media screen and (max-width: 767px) {
            flex-wrap: wrap;
        }
    }

    .HomeCategoryButton {
        @media screen and (max-width: 767px) {
            text-align: center;
        }
    }

    .CategoryIcon {
        padding-top: 30px;

        @media screen and (max-width: 767px) {
            flex-basis: 50%;
            text-align: center;
        }

        h3 {
            margin: 10px 0 0;
            font-size: 13px;
            font-family: $primaryFont;
            text-align: center;

            @media only screen and (min-width:501px) {
                margin: 15px 0 0;
            }
        }

        &:nth-child(even) {
            .IconwithRoundedBG {
                background: $base;
            }
        }
    }
}

.HomeProductsSection {
    position: relative;
    margin-bottom: 0;

    &:after {
        content: '';
        position: absolute;
        bottom: -70px;
        left: 0;
        right: 0;
        width: 100%;
        height: 200px;
        transform: skewY(5deg);
        background: #f5f4f7;
        z-index: -1;
    }

    .MerinoProductsDescription {
        background-size: 100%;
        background-position: 100%;
        background-color: #77191c;
        background-blend-mode: multiply;
        padding: 70px 0 150px;
        color: $white;

        h2 {
            color: $white;
        }
    }

    .MerinoProductsDetails {
        margin-top: -80px;

        .ImgContainer {
            position: relative;

            @media screen and (min-width: 960px) {
                margin-right: -30px;
            }

            // &:after {
            //   position: absolute;
            //   top: 0;
            //   right: -80px;
            //   width: 170px;
            //   height: 100.5%;
            //   background: #f5f4f7;
            //   transform: skewX(-15deg);
            //   @media screen and (min-width: 992px) {
            //     content: "";
            //   }
            // }
        }

        .TextContainer {
            @media screen and (min-width: 960px) {
                margin-left: -46px;
            }

            .TextWrapper {
                position: relative;
                background: $white;
                padding: 30px;
                height: 100%;
                box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
                z-index: 2;

                @media screen and (min-width: 960px) {
                    box-shadow: 8px 3px 10px rgba(0, 0, 0, 0.2);
                    padding: 50px 50px 50px 30px;

                }

                &:after {
                    position: absolute;
                    top: 0;
                    left: -60px;
                    width: 110px;
                    height: 100%;
                    background: $white;
                    transform: skewX(-13deg);
                    border-left: 10px solid $red;
                    box-shadow: -8px 3px 10px rgba(0, 0, 0, 0.2);
                    z-index: -1;

                    @media screen and (min-width: 992px) {
                        content: "";
                    }
                }
            }
        }
    }
    // .uk-grid{
    //     >li{
    //         margin-bottom: 15px;
    //     }
    // }
}
section.RemovedJoinMerino {
    background:none;
    padding-top: 40px;
    &::after {
        background: none;
    }
    @media only screen and (max-width: 768px) {
        padding-top: 70px;
    }
}
