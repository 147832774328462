.ModalSmallMainDiv{
	.uk-modal-body{
		padding: 50px 30px;
		@media(max-width: 400px){
			padding: 50px 15px;
		}
	}
	.uk-modal-dialog{
		width: 680px;
		margin: 0 5px;
		border-radius: 6px;
		@media(min-width: 380px){
			margin: 0 20px;
		}
		@media(min-width: 640px){
			width: 600px;
			margin: 0 auto;
		}
		@media(min-width: 768px){
			width: 680px;
		}
	}
	.Card{
		width: 100%;
		// border: 1px solid #333;
		@include Ease(all, 0.25s);
		&:hover{
			.Contents{
				color: $darkBackground;
				p{
					color: $darkBackground;
				}
			}
			.RoundButtonTransparent{
				border: 1px solid $red;
			}
		}
		.IconBox{
			border: 1px solid $primaryColor;
			width: 170px;
			margin-bottom: 0;
			border-radius: 4px;
			position: relative;
			padding: 18px 10px;
			@include Ease(all, 0.25s);
			p{
		    	margin-bottom: 0;
		    	color: $black;
		    	font-weight: 600;
		    	@include Ease(all, 0.25s);
		    }
			svg{
				width: 60px;
				height: 60px;
				fill: $lightGrey;
			}
		}
		.Contents{
			padding-top: 2rem;
			padding-bottom: 2rem;
			color: $lightGrey;
			@include Ease(all, 0.25s);
			p{
				color: $lightGrey;
				@include Ease(all, 0.25s);
			}
			p:last-child{
				margin-bottom: 0;
			}
		}
	}
}