.modal {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    // transition: opacity .15s linear;
    padding: 10rem;
    // overflow: auto;
    // transform: translate(-50%, -50%);
    -webkit-overflow-scrolling: touch;
    display: flex;
    max-width: 100%;
    max-height: 100%;

    // opacity: 0;
    // display: none;

    // &.open {
    //     opacity: 1;
    //     z-index: 999;
    //     display: flex;
    //     align-items: flex-start;

    //     .GalleryWithContent {
    //         opacity: 1;
    //         transform: translateY(0);
    //     }
    // }

    .GalleryWithContent {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: stretch;
        position: relative;
        // transition-property: opacity, transform;
        // transition: .3s linear;
        border-radius: 5px;
        // transform: translateY(-100px);
        // opacity: 0;
        box-sizing: border-box;
        box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.5);

        .image {
            flex: 0 0 70%;
            display: flex;
            position: relative;
            background: #000000;
            align-items: center;
            justify-content: center;


            img {
                width: auto;
                max-height: 800px;
            }

            .left-arrow {
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translateY(-50%);
            }

            .right-arrow {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
            }

            svg {
                color: $red;
                height: 50px;
                width: 50px;
            }
        }

        .Content {
            flex: 0 0 30%;
            background: $white;
            display: flex;
            flex-direction: column;
            overflow-y: auto;

            >div:not(:last-child) {
                border-bottom: thin solid rgba($lightGrey, .2);
            }

            .Thumb__WithContent {
                display: flex;
                justify-content: space-between;
                line-height: 1;
                padding: 20px;
                align-items: stretch;

                img {
                    flex: 0 0 25%;
                    max-width: 25%;

                    @media only screen and (max-width:600px) {
                        flex: unset;
                        max-width: 100%;
                    }
                }

                .Thumb__RightContent {
                    flex: 1;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;

                    @media only screen and (max-width:600px) {
                        flex: unset;
                    }

                    p {
                        margin-bottom: 0;
                        color: $lightGrey;
                    }

                    span {
                        font-weight: 700;
                        line-height: 1.2;

                        &.date {
                            color: rgba($lightGrey, .6);
                            font-weight: lighter;
                        }
                    }
                }

            }

            .SocialShare {
                padding: 20px;

                h3 {
                    font-size: 16px;
                    margin-bottom: 1.5rem;
                }
            }

            .Follow {
                padding: 10px 20px;

                ul {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin: 0;
                    padding: 0;

                    li {
                        display: inline-block;
                        font-size: 14px;
                        color: $black;
                        display: flex;
                        justify-content: center;

                        svg {
                            height: 20px;
                            width: 20px;
                            display: inline-block;
                            @include Ease(all, 0.25s);
                        }

                        .AlignedTextIcon {
                            align-items: baseline;
                            a {
                                @include Ease(all, 0.25s);
                                color: $base;

                                &:hover, {
                                    color: $red;
                                    svg{
                                        fill: $red;
                                    }
                                }
                                .LinkHoverSpan{
                                    display: flex;
                                    align-items: center;
                                }  
                            }
                             
                            span {
                                margin-left: 5px;
                                margin-top: 5px;
                            }
                        }

                    }

                }
            }

            .PhotoDetails {
                padding: 20px;
            }
        }

        .close {
            position: absolute;
            right: -65px;
            top: -40px;
            // transition-property: opacity;
            // transition: .1s ease-in-out;

            @media only screen and (max-width:990px) {
                right: -5px;
                top: -5px;
            }

            svg {
                color: $red;
                height: 60px;
                width: 60px;

                @media only screen and (max-width: 990px) {
                    width: 30px;
                    height: 30px;
                }
            }
        }


    }

    @media only screen and (max-width: 990px) {
        padding: 2rem;

        .GalleryWithContent {
            flex-direction: column;

            .image {
                flex: 0 0 50%;
            }

            .Content {
                flex: 0 0 50%;

                &::-webkit-scrollbar {
                    width: 9px;
                    background-color: $lightGrey;
                    border-radius: 3px;
                    // height: 12px;
                }

                &::-webkit-scrollbar-track {
                    background: #cccccc;
                    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
                    border-radius: 3px;

                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 3px;
                    background: $lightGrey;
                }
            }
        }
    }
}

.bounce-enter-active {
    animation: bounce-in .3s ease-in-out;
}

.bounce-leave-active {
    animation: bounce-in .3s reverse ease-in-out;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.01);
    }

    100% {
        transform: scale(1);
    }
}

.menu-popover-enter,
.menu-popover-leave-to {
    opacity: 0;
    transform: rotateY(50deg);
}

.menu-popover-enter-to,
.menu-popover-leave {
    opacity: 1;
    transform: rotateY(0deg);
}

.menu-popover-enter-active,
.menu-popover-leave-active {
    transition: opacity, transform 200ms ease-out;
}