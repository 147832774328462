.StatisSection{
	h2{
		font-size: 3rem;
		margin-bottom: 2rem;
	}
	.StatsBox{
		border-radius: 5px;
		padding: 3rem 2rem;
		.IconBox{
			svg{
				width: 45px;
				height: 45px;
				fill: $lightGrey;
			}
		}
		.Content{
			h3{
				font-size: 3.5rem;
				@media(min-width: 992px){
					font-size: 4.5rem;
				}
			}
			p{
				color: $lightGrey;
				margin-bottom: 0;
			}
		}
	}
}