.NotificationModal{
	.uk-modal-body{
		padding-left: 4rem;
		padding-right: 4rem;
	}
	.NotificationContainer{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		a{
			margin-top: 2rem;
		}
	}
}

.CookiesBox {
	display: none;
	position: fixed;
	bottom: 20px;
	padding: 15px;
	background-color: rgba(#333,.9);
	color: #fff;
	right: 20px;
	z-index: 13;
	max-width: 460px;
	border-radius: 3px;
	font-size: 15px;
	a.IconButton {
			&:hover {
				color:#000;
		}
	}
	h4 {
		color: #fff;
		font-size: 17px;
		font-family: Raleway,sans-serif;
	}
}
.uk-notification-message {
	font-size: 16px;
}
