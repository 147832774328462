.SmallHeading {
    font-size: 2.2rem;
    @media(min-width: 500px){
        font-size: 2.5rem;
    }
}

.SearchResultSection {

    padding-bottom: 70px;

    .Content {
        padding-bottom: 25px;
        padding-top: 15px;
    }

    .RecentGallerySection {
        h4 {
            font-size: 2rem;
            margin: 2rem 0 1.5rem;
        }

        // .RecentGallery {
        //     // padding-bottom: 25px;
        // }
    }

    .filter {
        max-width: 40%;
    }

    .Sidebar {
        margin-top: -105px;
    }


}

@media only screen and (max-width: 960px) {
    .SearchResultSection {
        .uk-grid {
            flex-direction: column-reverse;
        }
        #FilterSidebar {
            animation: SlideDown 0.8s;
            display: none;
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            z-index: 9996;
            overflow-y: auto;
            background: #fff;

            &.openSidebar {
                display: block;
                animation: SlideUp 0.3s;
                .MobileOnly {
                    display: block;
                    position: absolute;
                    top: 10px;
                    right: 20px;
                    color: $red;
                    font-weight: bold;
                    font-size: 20px;
                    .CloseSidebar {
                        cursor: pointer;
                         svg {
                            height: 25px;
                            width: 25px;
                        }
                    }
                   
                }
                .Sidebar__Heading {
                    display: none;
                }
            }
        }
        .Sidebar {
            margin-top: 0px;
            margin-bottom: 40px;
        }

        .filter {
            // width: 100%;
            max-width: 100%;
        }
        .MobileFilterSidebar {
            display: block;
            width: 100%;
            position: relative;
            opacity: 1;
            z-index: 9999;
            .Sidebar__Heading {
                padding: 15px;
                width: 100%;
                position: fixed;
                left: 0;
                right: 0;
                bottom: 0;
                color: $white;
                font-weight: 600;
                font-size: 1.8rem;
                display: flex;
                justify-content: center;
            }
        }
    }

}

@keyframes SlideUp{
    0%{
        transform: translateY(100%);
    }
    100%{
        transform: translateY(0);
    }
}
@keyframes SlideDown{
    0%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(100%);
    }
}
