.EmailVerificationModal{
	.uk-modal-body{
		padding: 0 0 50px 0;
	}
	.ModalHeadingWrapper{
		padding: 20px;
		border-radius: 6px 6px 0 0;
		@media(min-width: 500px){
			padding: 50px;
		}
		&.BGRed{
			color: $white;
			h2{
				color: $white;
			}
			h5{
				color: $white;
			}
		}
		@media(max-width: 500px){
			padding-top: 40px;
			.HeadingWithLefttIcon{
				display: block;
				text-align: center;
				.IconBox{
					margin: 0 auto 10px;
				}
				.headingBox{
					h2{
						font-size: 2rem;
					}
					h5{
						font-size: 1.4rem;
					}
				}
			}
		}
	}
	.ModalContentWrapper{
		padding: 30px 20px 0;
		@media(min-width: 500px){
			padding: 30px 80px 0;
		}
		h4{
			font-size: 1.8rem;
			font-weight: 300;
		}
	}
	form{
		input[type="text"]{
			text-align: center;
			width: 80%;
			margin: 0 auto;
			font-size: 18px;
			@media(min-width: 500px){
				width: 60%;
			}
		}
		p{
			color: $lightGrey;
		}
		.VerifyButton{
			margin-top: 2rem;
		}
	}
}