.HomeSliderContainer {
  position: relative;
  .border-line {
    background-image: url('../img/border.jpg');
    position: absolute;
    left: 0;
    bottom: 6px;
    right: 0;
    height: 4px;
    background-repeat: repeat;
    // @media only screen and (max-width: 768px) {
    //   display: none;
    // }
  }
}

.HomeSlider {
  overflow: hidden;
  .Slide {
    position: relative;
    .SlideText {
      font-weight: 700;
      font-size: 20px;
      font-family: $secondaryFont;
      color: $white;
      position: absolute;
      bottom: 45%;
      // left: 50%;
      // transform: translateX(-50%);
      text-align: center;
      width: 100%;
      margin: 0 auto;
      
      @media screen and (min-width: 1024px) {
        font-size: 40px;
        font-weight: normal;
        bottom: auto;
        top: 40%;
      }
      .SlideSmallText {
        display: block;
        font-size: 16px;
        margin-top: 10px;

        span:not(:last-child) {
          margin-right: 7px;
        }
        @media only screen and (max-width: 1023px) {
          font-size: 12px;
          font-weight: 500;
        }
      }
      
    }
    &:before {
      content: '';
      @include Overlay();
      background: rgba(58, 11, 11, 0.3);
      background-blend-mode: multiply;
    }
  }
  
}


.ExpolreProjectsSliderContainer {
  margin-top: -20px;
  @media only screen and (min-width: 960px) {
  .uk-grid-small {
    margin-left: 0;
    box-shadow: 1px 4px 7px rgba(0,0,0,.1);
    [class*='uk-width'] {
      padding-left: 0;
    }
  }
}
  .LeftContent {
    background-color: $white;
    padding: 20px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    box-shadow: -5px 0 8px rgba($black, 0.1);
    p {
      margin: 0;
    }
  }
}
.ExpolreProjectsSliderWrapper {
  // box-shadow: 5px 0 8px rgba($black, 0.1);
  @media screen and (min-width: 960px) {
    background-color: $white;
  }
}
.ExpolreProjectsSlider {
  background-color: $white;
  height: 100%;
  @media only screen and (max-width: 960px) {
    box-shadow: 1px 4px 8px rgba($black, 0.1);
  }
  .uk-slider-items {
    height: 100%;
  }
  li:not(:last-child) {
    .Slide {
      border-right: 1px solid #dedede;
    }
  }
  li a {
    text-decoration: none;
    color: inherit;
    width: 100%;
    height: 100%;
    .Slide {
      height: 100%;
      color: $base;
      background-color: $white;
      @include FlexCenter();
      flex-direction: column;
      padding: 20px;
      @include Ease(0.3s);
      svg {
        width: 40px;
        height: 60px;
        @include Ease(0.3s);
      }
    }
    &:hover {
      .Slide {
        background-color: #f4f4f4;
      }
    }
  }
}

.SubheadwithSlideNav {
  @media screen and (min-width: 768px) {
    display: flex;
  }

  .uk-slidenav-container {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    @media screen and (min-width: 768px) {

    }
  }
  .SmallContainer {
    @media screen and (min-width: 768px) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}
.LatestProjectsSlider {
  @media only screen and (min-width: 960px) {
  .TwoSideBendCardswithImg {
    .uk-grid-small {
      margin-left: -15px;
    }
  }
}
  padding-top: 50px;
  @media(max-width: 768px){
    .uk-slider-container{
      padding-bottom: 70px;
      .uk-slidenav-container{
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.uk-slidenav {
  color: $base;
  &.Small {
    svg {
      width: 10px;
    }
  }
  &.withBG {
    background: $white;
    padding: 15px 20px;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    margin: 5px;
    svg {
      width: 8px;
    }
    &:hover {
      background: $red;
      svg {
        color: $white;
      }
    }
  }
}

.LeftProductCategoriesSlider {
  .uk-slider{
    // height: 100%;
  }
  ul {
    height: 100%;
    li {
      // width: 100%;
      img {
        width: 100%;
        height: 100%;
        border: 10px solid rgba($white, .5);
        border-radius: 10px;
      }
    }
  }
}
.RightProductCategoriesSlider {
  @media screen and (min-width: 1024px) {
    // margin-left: -100px;
  }
  .SmallContainer {
    @media screen and (min-width: 768px) {
      max-width: 600px;
    }
  }
  .SubheadwithSlideNav {
    @media screen and (min-width: 1024px) {
      // margin-left: 60px;
    }
  }
  .SliderWrapper {
    @media only screen and (max-width: 640px) {
      .uk-child-width-1-2\@s > * {
        width: 50%;
      }
    }
    .uk-child-width-1-3\@m>* {
      margin-bottom: 2rem;
    }
    padding-top: 30px;
    .Card {
      a {
        color: $base;
        width: 100%;
        &:hover img {
          transform: scale(1.05) translateZ(0);
        }
      }
      img {
        width: 100%;
        border: 5px solid $white;
        // border-radius:5px;
        box-shadow: 0 2px 3px rgba(0,0,0,.3);
        // filter: brightness(0.75) saturate(1.2) contrast(0.85);
        transition: .6s all;
        transform-origin: center;
        transform: scale(1) translateZ(0);
        transition: 
          transform 200ms linear;
      }
      .Caption {
        margin: 10px 0 0;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
      }
    }
  }
  @media(max-width: 768px){
    .uk-slider-container{
      padding-bottom: 70px;
      .uk-slidenav-container{
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}