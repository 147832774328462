.Header {
	position: relative;
	z-index: 995;
	.uk-navbar {
		display: flex;
		position: relative;
	}
	& > div {
		background-color: $red;
		box-shadow: 0 3px 6px rgba($black, 0.3);
	}

	.MainLogo {
		// width: 250px;
		transform: skewX(25deg);
		padding: 20px 30px;
		display: flex;
		height: 100%;

		@media only screen and (max-width: 1100px) and (min-width: 1024px) {
				// width: 220px;
		}
	}

	.OtherMenuItem {
		display: flex;
		align-items: center;

		a {
			height: auto;
			// background-color: $white;
			color: $white;
			font-weight: 600;
			transform: none;
			border-radius: 30px;
			padding: 0 20px;
			@include Ease();

			span {
				transform: none;
			}
		}

		&:hover,
		&:focus {
			a {
				background-color: $white;
				color: $red;
			}
		}

		&.Highlighted {
			a {
				background-color: $white;
				color: $red;
				margin: 0 15px;
			}

		}

		a:hover,
		a:focus {
			background-color: $white;
			color: $red;
		}
	}

	.OptionMenuItem {
		padding-left: 20px;

		.uk-link {
				color: $white;
		}

		.uk-icon {
				color: $white;
		}

		// .uk-form-custom {
		//   padding-left: 15px;
		// }
		.selectize-control {
			width: 50px;
			line-height: 1;

			.selectize-input {
				background: 0 !important;
				border: 0 !important;
				// box-shadow: none;
				color: $white !important;
				font-size: 16px;
				font-weight: 600;
				padding: 0;

				&:after {
					right: 7px;
					background-image: url(../img/arrow-white.svg);
					background-color: transparent;
					top: 65%;
					width: 14px;
					height: 9px;
				}

				&.input-active {
					&:after {
							top: 50%;
					}

					input {
							cursor: pointer !important;
					}
				}

				>input[type="select-one"] {
					width: 1px !important;
				}
			}

			.selectize-dropdown {
				margin-top: 10px;
				border: 0;
				font-size: 14px;

				.selectize-dropdown-content {
					padding: 5px 0;
				}

				.active {
					color: $red;
				}

				.option {
					cursor: pointer;
					padding: 5px 10px;
				}
			}
		}
	}

	.ProfileMenu {
		&>a {
			font-weight: 600;

			&.uk-open {
					color: $white !important;
			}

			&:hover,
			&:focus {
					color: $white !important;
			}
		}

		&:hover,
		&:focus {
			>a {
					background-color: transparent;
					color: $white !important;
			}
		}

		.ProfileMenuItem {
			display: flex;
			align-items: center;

			.ProfileImg {

				// flex-basis: 30%;
				img {
					height: 40px;
					width: 40px;
					border-radius: 50%;
					object-fit: cover;
				}
			}

			.ProfileLabel {
				margin-left: 10px;
				display: flex;
				flex-direction: column;
				span {
					width: 100%;
					display: flex;
					align-items: center;
					flex-wrap: wrap;
				}
			}
		}

		.uk-navbar-dropdown {
			width: 150px;
		}


	}

	input[type="submit"] {
		border: 0;
		background: 0;
		color: $white;
		cursor: pointer;
		margin: 0;
		padding: 5px 0;
		font-size: 14px;
		font-weight: 600;

		@media screen and (min-width: 1024px) {
			color: #999;
			font-weight: normal;
		}

		&:hover {
			color: $red;
		}
		@media only screen and (max-width: 1024px) {
			&:hover {
			color: $white;
			}
		}
	}

	.NavDesktopOnly{
		.uk-navbar-nav{
			> li{
				> a{
					.MenuBg{
						min-height: 40px;
						display: flex;
						align-items: center;
						font-size: 15px;
						@include Ease(all, 0.25s);
					}
				}
			}
		}   
	}

	.MenuLabel {
		svg {
			width: 20px;
			height: 20px;
		}
	}

}



.NavDesktopOnly {
	height: 100%;

	@media screen and (max-width: 1024px) {
			display: none !important;
	}

	// .uk-navbar-nav>li>a {
	// 		@media only screen and (max-width: 1100px) and (min-width: 1024px) {
	// 				font-size: 14px;
	// 		}
	// }

	@media only screen and (max-width:1230px) {
		.uk-navbar-nav>li>a {
			font-size: 13px;
		}
		.MenuLabel svg {
			width: 20px;
			height: 20px;
		}
	}
	@media only screen and (max-width:1140px) {
		.uk-navbar-nav>li>a {
			font-size: 12px;
		}
	}
	.ProfessionalMegaMenu {
			.uk-navbar-dropdown {
					margin-top: 0;

					&.uk-open {

							ul.uk-navbar-dropdown-nav {
									z-index: 999999;
									position: relative;
									display: flex;
									align-items: center;
									// justify-content: space-between;
									max-width: 100%;
									padding: 0;
									transition: display .2s ease-in-out 0s;
									// background: $red;

									li {
											padding: 15px;
											flex: 0 0 25%;

											&:not(:last-child) {
													padding-right: 10px;
													border-right: 1px solid rgba($lightGrey, .4);
											}

											// flex: 0 0 25%;
											.MenuCard {
													@include FlexCenter();
													flex-direction: column;
													// box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
													padding: 20px;
													border-radius: 3px;
													@include Ease();

													.circleIcon {
															height: 70px;
															width: 70px;
															border-radius: 50px;
															background: rgba($lightGrey, .2);
															@include FlexCenter();
													}

													svg {
															width: 40px;
															height: 40px;
															// fill: white;
															// stroke: $white;
													}

													span {
															margin-top: 10px;
															font-size: 1.8rem;
															font-weight: 600;
															color: $black;
															transition: color .4s;
													}

													&:hover {
															// box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
															// box-shadow: 0 4px 16px rgba($black, .3);

															span {}
													}
											}

									}
							}

							.MegaMenuOverlay {
									opacity: 1;
									z-index: 999;
									position: fixed;
									top: 100px;
									left: 0;
									right: 0;
									bottom: 0;
									background: rgba(0, 0, 0, .6);
									z-index: 999;
									text-indent: -999px;
									transition: all .2s ease-in-out 0s;
									pointer-events: none;
							}
					}
			}

	}

	.CategoriesMegaMenu {
			.uk-navbar-dropdown {
					margin-top: 0;
					box-shadow: unset;
					&.uk-open {
							> ul.uk-navbar-dropdown-nav {
									z-index: 999999;
									position: relative;
									display: flex;
									align-items: center;
									justify-content: flex-start;
									width: 100%;
									// padding: 20px;
									padding: 0;
									transition: display .2s ease-in-out 0s;

									.MainCategory {
											// padding: 10px 0;
											font-size: 2rem;
											font-weight: 600;
											flex: 0 0 33.32%;
											display: flex;
											justify-content: center;
											width: 33.33%;
											&:not(:last-child) {
												border-right: 1px solid rgba($lightGrey, .4);
												// margin-bottom: 10px;
												// padding-bottom:20px;
											}
											.MainCategoryName {
												display: block;
												cursor: pointer;
												width: 100%;
												height: 100%;
												.MenuCard {
													@include FlexCenter();
													flex-direction: column;
													// box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
													padding: 20px;
													border-radius: 3px;
													@include Ease();

													.circleIcon {
															height: 70px;
															width: 70px;
															border-radius: 50px;
															background: rgba($lightGrey, .2);
															@include FlexCenter();
													}

													svg {
															width: 40px;
															height: 40px;
															// fill: white;
															// stroke: $white;
													}

													span {
															margin-top: 10px;
															font-size: 1.8rem;
															font-weight: 600;
															color: $black;
															transition: color .4s;
													}

													&:hover {
															// box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
															// box-shadow: 0 4px 16px rgba($black, .3);

															span {}
													}
												}
											}

											ul.SubCategory {
												display: flex;
												flex-wrap: wrap;
												padding: 0;
												padding: 20px;
												border-top: 2px solid $red;

												li {
													flex: 0 0 16.66%;
													font-size: 1.4rem;
													font-weight: 500;
													color: $black;
													line-height: 3;
													transition: color .4s;
													// margin-top: 15px;

													&:hover {
															a {
																	color: $red;
															}
													}
													.MenuCard {
														display: flex;
														align-items: center;
														// @include FlexCenter();
														// box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
														// padding: 20px;
														border-radius: 3px;
														@include Ease();

														.circleIcon {
																height: 50px;
																width: 50px;
																border-radius: 50px;
																background: rgba($lightGrey, .1);
																margin-right: 10px;
																@include FlexCenter();
																.icon {
																	height: 30px;
																	width: 30px;
																}
														}
													}
													@media screen and (max-width:1200px) {
														.MenuCard {
															span {
																font-size: 12px;
															}
														}
													}
												}
											}
									}
							}

							.MegaMenuOverlay {
									opacity: 1;
									z-index: 999;
									position: fixed;
									top: 103px;
									left: 0;
									right: 0;
									bottom: 0;
									background: rgba(0, 0, 0, .6);
									z-index: 999;
									// text-indent: -999px;
									transition: all .2s ease-in-out 0s;
									pointer-events: none;
							}
					}
			}
	}


}

.NavMobileOnly {
	@media screen and (min-width: 1025px) {
			display: none !important;
	}
}

@media screen and (max-width: 1024px) {

	.uk-navbar-left {
			position: relative;

			&:before {
					content: '';
					position: absolute;
					top: 0;
					bottom: 0;
					left: -22%;
					height: 100%;
					width: 30%;
					background: $white;
					transform: skewX(20deg);
			}

			.MainLogo {
					padding: 10px 25px 10px 0;
					width: 180px;
			}
	}

	.MobileMenuController {
			color: $white !important;
			font-size: 13px;
			display: flex !important;
			align-items: center;

			.MenuLabel {
					margin-right: 10px;
					opacity: 1;
					@include Ease(0.5);
			}

			.MenuTogglebar {
					display: flex;
					flex-direction: column;
					align-items: flex-end;

					.ToggleBar {
							@include Ease(0.2s);
							height: 2px;
							background-color: $white;
							position: relative;

							&.Diagonal-1 {
									width: 24px;
							}

							&.Horizontal {
									margin-top: 5px;
									width: 14px;
							}

							&.Diagonal-2 {
									width: 20px;
									margin-top: 5px;
							}
					}
			}
	}

	.MobileNavConatiner {

			.uk-offcanvas {
					right: 0;
			}

			.uk-offcanvas-bar {
					left: -100%;
					width: 100%;
					background: $red;
			}
	}

	.uk-offcanvas {
			&.uk-open {
					&>.uk-offcanvas-bar {
							left: 0;
							padding-top: 60px;
							padding-bottom: 40px;
							font-weight: 600;
							color: $white;
							>ul.uk-nav {
								margin-top: 20px;
							}

							ul.uk-nav>li {
									border-bottom: solid thin rgba($white, 0.3);

									&:first-child {
											// border-top: solid thin rgba($white, 0.3);
									}

									&>a {
											padding: 10px 0;
											// display: inline-block;
											padding-right: 35px;

									}

									.uk-nav-sub {
											padding-top: 0;

											li a {
													padding: 5px 0;
													font-size: 14px;
											}
									}
							}

							.uk-parent {
									a:after {
											background-image: url(../img/arrow-white.svg);
											transition: all .2s linear;
											position: absolute;
											right: 20px;
											float: none;
									}

									&.uk-open {
											a:after {
													transform: rotate(-180deg);
											}
									}
							}

							.OptionMenuItem {
								margin-top: 100px;
									// position: absolute;
									// bottom: 50px;
									// left: 20px;
									// right: 20px;
									padding-left: 0;
									border-bottom: solid thin rgba($white, 0.3);

									.uk-form-custom,
									.selectize-control {
											width: 100%;
									}
							}

							.ProfileMenu {
									&>a {
											font-weight: 600;

											&:after {
													content: none;
											}
									}
							}
					}
			}

	}

	body.uk-offcanvas-container {
			.MobileMenuController {
					padding: 10px 0 10px 10px;

					.MenuLabel {
							opacity: 0;
					}

					.MenuTogglebar {
							z-index: 9999;
							padding-top: 9px;
							height: 20px;

							.ToggleBar {
									&.Horizontal {
											opacity: 0;
									}

									&.Diagonal-1 {
											transform: rotate(135deg);
											margin-top: 0;
									}

									&.Diagonal-2 {
											width: 24px;
											transform: rotate(-135deg);
											margin-top: -9px;
									}
							}
					}
			}
	}
	li.customMenuParent {
		position: relative;
			.uk-nav-sub {
				// overflow: hidden; // Hide the element content, while height = 0
		  //   height: 0; opacity: 0;
		  //   transition: height 0ms 400ms, opacity 400ms 0ms;
		  			max-height: 0;            
            overflow-y: hidden;
            transition: max-height .5s ease-in-out;
            li.mainSubMenuListItem {
            	padding: 5px 0;
            }
            li.subMenuListItem {
            	padding-left: 15px;
            }
			}
			&.active {
				padding-bottom: 10px;
				.uk-nav-sub {
					max-height: 1200px;
  				// height: auto; opacity: 1;
    		// 	transition: height 0ms 0ms, opacity 600ms 0ms;
				}
				.SubMenuController {
					transform: rotate(-180deg);
					}
				}
			.SubMenuController {
				position: absolute;
				top: 0;
				right: 0;
				height: 2em;
				width: 2em;
				padding: 10px;
				transition: all .2s linear;
				padding-right: 0 !important;
				&::after {
					content: '';
					background-image: url(../img/arrow-white.svg);
					transition: all .2s linear;
					position: absolute;
					right: 7px;
					top: 50%;
					height: 9px;
					width: 14px;
					background-repeat: no-repeat;
				}
				
			}
		}
}