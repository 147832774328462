.FormPageSection {
    padding-top: 16px;
    padding-bottom: 16px;

    h3 {
        font-size: 2rem;
        margin-bottom: 2rem;

        @media(min-width: 500px) {
            font-size: 2.3rem;
        }
    }

    .FormWrapperBox {
        padding: 20px;

        @media(min-width: 500px) {
            padding: 30px 40px 40px;
        }

        &.uk-grid-small {
            margin-left: 0;
            // padding-left: 0;
        }
        .uk-grid-margin {
            margin-top: 16px;
        }

    }
}