.UploadPhotoFormContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    text-align: center;
    border: 1px solid rgba($lightGrey, .3);
    border-radius: 3px;
    flex-wrap: wrap;
    position: relative;
    &:first-child{
        .CloseBtnCircle{
            display: none;
        }
    }
    >div {
        flex: 0 0 33.3%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-self: stretch;
        align-items: stretch;

        @media only screen and (max-width: 900px) {
            flex: 0 0 100%;
        }

        .Heading {
            padding: 10px;
            border-bottom: 1px solid rgba($lightGrey, .3);

            &:not(:last-child) {
                border-right: 1px solid rgba($lightGrey, .3);
            }

            &.Noborder {
                border-right: 0;
            }

            @media only screen and (max-width: 900px) {
                border-top: 1px solid rgba($lightGrey, .3);

                &:first-child {
                    border-top: none;
                }
            }
        }

        .PhotoUploadBody {
            padding: 20px;
            border-right: 1px solid rgba($lightGrey, .3);
            height: 100%;

            .uk-placeholder {
                margin-bottom: 0;
            }

            .UploadFileMainbox {
                .RoundButtonTransparent {
                    margin-right: 0;
                }

                .FileText {
                    display: block;
                    margin-top: 20px;
                    font-size: 15px;
                }
            }

            &.Noborder {
                border-right: 0;
            }

            .mce-tinymce {
                box-shadow: none;
            }

            .mce-statusbar>.mce-container-body {
                display: none;
            }
        }
    }
    .CloseBtnCircle{
        position: absolute;
        right: -35px;
        top: 50%;
        @media(max-width: 500px){
            right: 10px;
            top: 7px;
        }
    }
}

.ProjectPhotoEditDiv{
    .close{
        position: absolute;
        right: -5px;
        top: -5px;
        background-color: $red;
        padding: 6px;
        width: 30px;
        height: 30px;
        color: $white;
        border-radius: 50%;
        z-index: 11;
        @include Ease(0.25s);
        &:hover{
            background-color: darken($red, 10%);
        }
            button{
                color: $white;
            }
        }
    img{
        width: 100%;
    }
}

.TabSection{
    .Tabs{
        @media(max-width: 500px){
            li{
               padding-left: 5px;
               padding-right: 5px;
               a{
                    font-size: 1.35rem;
                    padding: 5px 0;
               }
            }
        }
        @media(max-width: 375px){
            li{
                padding-left: 3px;
                padding-right: 3px;
                a{
                    font-size: 1.25rem;
                }
            }
        }
    }
}



