.ModalContainerMain{
	.uk-modal-dialog{
		width: 900px;
	}
	.uk-modal-body{
		padding: 0;
	}
	.ModalFormBodyWrap{
		// padding-left: 0;
		// @media(min-width: 640px){
		// 	padding-left: 30px;
		// }
	}
	.ModalBackGrounImg{
		background-image: url(../img/tempimg/furniture.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		height: 100%;
		position: relative;
		&:before{
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			height: 100%;
			border-radius: 6px 0 0 6px;
			background-color: rgba(237, 50, 55, 0.8509803921568627);
		}
	}
	.ModalFormBody{
		padding: 50px 20px;
		@media(min-width: 640px){
			padding: 50px 40px 50px 0;
		}
		
		form{
			.selectize-input{
				border: 1px solid darken($lightBackground, 10%);
			}
			.RegisterNow{
				&.uk-text-right{
					text-align: center !important;
					@media(min-width: 640px){
						text-align: right !important;
					}
				}
			}
		}
	}
	&.RegisterAsPro{
		.HeadingWithLefttIcon{
			@media(max-width: 500px){
				display: block;
				text-align: center;
				.IconBox{
					margin: 0 auto 10px;
				}
			}
			.headingBox{
				@media(max-width: 500px){
					h2{
						font-size: 2rem;
					}
					h5{
						font-size: 1.4rem;
					}
				}
			}
		}
	}
}
