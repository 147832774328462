@media only screen and (max-width:960px) {
    .RecentGallerySection {
        .RecentGallery {


            .uk-grid {
                flex-direction: row;

                >div {
                    flex: 0 0 50%;
                }
            }
        }
    }
}

.uk-lightbox.uk-open {
    z-index: 9999;
}