.NotFound {
  @include FlexCenter();
  flex-direction: column;
  height: 50vh;
  background: $lightBackground;
  .Status {
    font-weight: 700;
    font-size: 60px;
    font-family: $secondaryFont;
    margin-bottom: 0;
    line-height: 1;
  }
  .Conetnts {
    margin: 40px 0;
    font-weight: 700;
    font-size: 20px;
  }
  .Button {
    a {
      padding: 15px 120px 15px 40px;
    }
  }
}
