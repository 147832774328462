.SearchButton{
    background-color: $red;
    border: 1px solid $red;
    padding: 1rem 1rem;
    @include Ease(all, 0.25s);
    svg{
        width: 25px;
        height: 25px;
        fill: $white;
        transform: scale(0.9);
        @include Ease(all, 0.25s);
    }
    &:hover{
        background-color: darken($red, 10%);
        svg{
            transform: scale(1) rotate(-8deg);
        }
    }
}

.RoundButton {
    display: inline-block;
    padding: 8px 30px;
    text-align: center;
    color: $white;
    background: $darkBackground;
    border-radius: 100px;
    margin: auto 0;
    font-size: 16px;
    line-height: 1;
    text-decoration: none;
    @include Ease(all, 0.25s);
    &:hover {
        background: $red;
        color: $white;
    }
    &:hover {
        background: $darkBackground;
        color: $white;
    }
    &.Red {
        background: $red;

        &:hover {
            background: $darkBackground;
        }
    }

    

    &.RedBG {
        background: $red;
        color: $white;
        border: 1px solid $red;
        @include Ease(all, 0.25s);

        &:hover {
            background: $white;
            color: $red;
        }
        &.NotHoverBtn{
            &:hover{
                background: $red;
                color: $white;
            }
        }
    }


}

.RoundButton {
    &.BigRoundBtn {
        padding: 14px 35px;
    }
}

.RoundButtonTransparent {
    display: inline-block;
    padding: 14px 40px;
    text-align: center;
    color: $red !important;
    background: $white;
    border: 1px solid $red;
    border-radius: 100px;
    margin: auto 0;
    font-size: 16px;
    line-height: 1;
    cursor: pointer;
    text-decoration: none;
    position: relative;
    font-weight: 700;
    @include Ease(all, 0.25s);

    &:hover {
        border: 1px solid $red;
        background: $red;
        color: $white !important;
        svg{
            fill: $white;
        }
    }

    svg {
        position: absolute;
        width: 15px;
        height: 15px;
        left: 22px;
        fill: $red;
        @include Ease(all, 0.25s);
        top: 50%;
        transform: translateY(-50%);
    }
    &.BtnCross{
        svg{
            transform: rotate(45deg);
        }
    }
}

.TrnasparentWhiteBtn {
    display: inline-block;
    padding: 14px 35px;
    text-align: center;
    color: $white !important;
    background: transparent;
    border: 1px solid $white;
    border-radius: 100px;
    margin: auto 0;
    font-size: 16px;
    line-height: 1;
    cursor: pointer;
    text-decoration: none;
    @include Ease(all, 0.25s);

    &:hover {
        border: 1px solid $white;
        background: $white;
        color: $red !important;
    }
}

button.ArrowedButton,
a.ArrowedButton {
    position: relative;
    background: $white;
    border: 1px solid $red;
    padding: 14px 100px 14px 20px;
    min-width: 180px;
    text-decoration: none;
    color: $red;
    font-weight: 600;
    font-size: 15px;
    text-align: initial;
    @media(max-width: 500px){
        min-width: 165px;
        padding: 12px 85px 12px 18px;
    }
    .ArrowBG {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 30%;
        background: $red;
        z-index: 3;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: -10px;
            right: 0;
            width: 20px;
            height: 101%;
            background: $red;
            transform: skewX(-20deg);
            z-index: -1;
        }

        .Arrow {
            content: "";
            position: absolute;
            top: 50%;
            left: 30%;
            transform: translate(-50%, -50%);
            z-index: 2;
            color: $white;
            @include Ease(left, 0.25s);
            .ArrowIcon {
                width: 20px;
                height: 20px;
                fill: $white;
            }
        }
    }

    &:hover {
        color: $red;

        .Arrow {
            left: 40%;
        }
    }

    &.Block {
        display: block;
        padding: 20px 100px 20px 50px;

        .ArrowBG {
            width: 25%;

            &:before {
                width: 25px;
                left: -13px;
            }

            .Arrow {
                left: 40%;
            }
        }

        &:hover {
            .Arrow {
                left: 50%;
            }
        }
    }

    &.RedBG {
        background: $red;
        color: $white;
        border: 0;

        .ArrowBG {
            background: $black;

            &:before {
                background: $black;
                height: 100%;
            }
        }

        &:hover {
            color: $white;
        }
    }
}

.Button-MT {
    margin-top: 30px;
}

button {
    &:focus {
        outline: none;
        // border: none;
        cursor: pointer;
    }
}
button.SquareButton,
a.SquareButton {
    border: none;
    outline: none;
    color: $white;
    background: $red;
    padding: 15px 40px;
    font-weight: bold;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}


.IconButton {
    padding: 0.7rem 3rem;
    border-radius: 4px;
    background-color: $red;
    text-align: center;
    border: 1px solid $red;
    @include Ease(all, 0.25s);

    svg {
        width: 28px;
        height: 28px;
        padding: 4px;
        fill: $white;
        @include Ease(all, 0.25s);
    }

    &:hover {
        background-color: $white;

        svg {
            fill: $red;
        }
    }

    &.fbButton {
        background-color: $facebookColor;
        border-color: $facebookColor;

        &:hover {
            background-color: $white;

            svg {
                fill: $facebookColor;
            }
        }
    }

    &.GoogleButton {
        background-color: $googleColor;
        border-color: $googleColor;

        &:hover {
            background-color: $white;

            svg {
                fill: $googleColor;
            }
        }
    }

}

.ForgrtPasswordLink {
    text-decoration: underline !important;
    @include Ease(all, 0.25s);

    &:hover {
        color: $red;
    }
}

.ButtonDiv {
    margin-top: 2rem;
    margin-bottom: 1.5rem;

    a {
        font-size: 1.5rem;
        font-weight: 600;
        @media(min-width: 500px){
           font-size: 1.8rem; 
        }
    }
}

.CloseBtnCircle{
    width: 30px;
    height: 30px;
    // background-color: 2px solid $red;
    background-color: #989898;
    border-radius: 50%;
    padding: 0.45rem 0.5rem;
    cursor: pointer;
    border: unset;
    // transform: translateY(-50%);
    // opacity: 0.4;
    @include Ease(all, 0.25s);
    &:hover{
        // opacity: 1;
        background-color: $red;
    }
    svg{
        width: 100%;
        height: 100%;
        fill: $white;
        transform: rotate(45deg);
    }
}

